<template>
  <div class="NotFound404">
    <div class="nf-content">
      <div class="nf-text">
        <p class="nf-text-title">404</p>
        <p class="nf-text-description">irgendetwas fehlt</p>
        <p class="nf-text-button" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="$router.push({name: 'list'})">zurück zur Liste</p>
      </div>
      <div v-for="i in 30" :key="i" class="nf-stripe"/>
    </div>
    <Navbar/>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';

import Navbar from '@/components/Navbar.vue'

export default {
  components: {Navbar},
  methods: {
    ...mapMutations(['changeCursor',])
  },

}
</script>

<style scoped>
.NotFound404{
  background: var(--Vibrant);
  height: 100%;
  overflow: hidden;
}
.nf-content{
  height: calc(100% - 60px);
  text-align: center;
}
.nf-stripe{
  display: inline-block;
  width: 5px;
  background: var(--darkMuted);
  margin: 0 40px;
  height: 100%;
}
.nf-text{
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.nf-text-title{
  color: var(--lightVibrant);
  font-size: 300px;
  margin: 0;
}
.nf-text-description{
  color: black;
  font-size: 70px;
  margin: 0;
  margin-bottom: 50px;
}
.nf-text-button{
  color: var(--Vibrant);
  background: var(--lightVibrant);
  font-size: 30px;
  margin: 0;
  padding: 10px;
}
.nf-text-button:hover{
  background: var(--darkMuted);
}

/* @media only screen and (max-width: 600px) { */
  .NotFound404{
    width: 100vw;
  }
  .nf-text{
    width: 90vw;
  }
  .nf-text-title{
    font-size: 130px;
  }
  .nf-text-description{
    font-size: 28px;
    margin-bottom: 30px;
  }
  .nf-text-button{
    font-size: 20px;
    padding: 10px;
  }
  .nf-stripe{
    width: 100%;
    margin: 25px 0px;
    height: 3px;
  }
/* } */
</style>
