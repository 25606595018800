<template>  
  <div class="filtertitle" @click="setCurrentDetailFilterAtIndex({'index': index, 'detailFilter': null})" @mouseenter="activeFilters[index] != null ? changeCursor('pointer') : ''" @mouseleave="changeCursor('default')">
    <div class="filter-cross"/>
    <h1 class="filter-slideout-title">
      <transition name="filterTextAnimation" mode="out-in">
        <span @mouseenter="titleHover(true)" @mouseleave="titleHover(false)" :key="filterTitle.id">{{filterTitle}}</span>
      </transition>
    </h1>
  </div>
  <div class="Sliderbox">
    <Slider :id="'defaultSlider'+index" class="slider default-slider" v-model="sliderValue" :orientation="landscape ? 'vertical' : 'horizontal'" :direction="landscape ? 'ltr' : 'rtl'" :tooltips="false" :max="optionsLength" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import Slider from '@vueform/slider'

export default {
  components: {Slider},
  props: ['index','optionsLength'],
  computed: {
    ...mapGetters(['detailFilters','activeFilters','landscape']),
    filterTitle(){
      return this.activeFilters[this.index] != null ? this.detailFilters[this.index].options[this.activeFilters[this.index]].title : this.detailFilters[this.index].title;
    },
    sliderValue: {
      get() {
        // Change filter color when selected
        let slider = document.getElementById("defaultSlider"+this.index);
        let currentDetailFilter = this.activeFilters[this.index];
        if(currentDetailFilter != null && slider)
          if(currentDetailFilter == parseInt(this.optionsLength/2))
            slider.classList.remove("slider-selected")
          else
            slider.classList.add("slider-selected")
        else
          if(slider)
            slider.classList.remove("slider-selected")
        if(currentDetailFilter != null)
          return currentDetailFilter;
        return parseInt(this.optionsLength/2);
      },
      set(value) {
        this.setCurrentDetailFilterAtIndex({'index': this.index , 'detailFilter': value == parseInt(this.optionsLength/2) ? null : value})
      }
    }
  },
  methods: {
    ...mapMutations(['setCurrentDetailFilterAtIndex','changeCursor']),
    titleHover(bool){
      let slider = document.getElementById("defaultSlider"+this.index);
      bool ? slider.classList.add("slider-selected-hover") : slider.classList.remove("slider-selected-hover")
    }
  },
}
</script>

<style>

</style>