<template>
  <div class="category ProjectFilter" :class="{'expanded': expanded, 'filterActive': activeCategory}" :style="{maxHeight: expanded ? (landscape ? 70 * categories.length + 95 + 'px' : 50 * categories.length + 60 + 'px' ) : ''}">
    <div class="filter-head" v-on:click="$emit('toggleSlideout')" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"> 
      <div class="filter-arrow" />
      <div class="filter-cross" @click="setActiveCategory(null);" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/>
      <h1 class="filter-title"><span class="filter-title-text">{{categoryFilterTitle}}</span></h1>
    </div>
    <div class="filter-slideout-element" v-for="category in categories" :key="category.id" @click="setActiveCategory(category == activeCategory ? null : category)" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" :class="{'CategoryFilterActive': category == activeCategory}">
      <div class="filter-cross"/>
      <p class="filter-slideout-title" >{{category.title}}</p>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';

export default {
  props: ['changeFilterTitleSize','expanded'],
  watch: {
    categoryFilterTitle(){
      setTimeout(() => { this.changeFilterTitleSize(0); }, 0);
    },
  },
  computed: {
    ...mapGetters(['categories','activeCategory','landscape']),
    categoryFilterTitle(){
      return this.activeCategory ? this.activeCategory.title : "Kategorien";
    }
  },
  methods: {
    ...mapMutations(['setActiveCategory','changeCursor'])
  },
}
</script>

<style scoped>
.filter-slideout-title:hover{
  color: var(--darkVibrant);
}
</style>