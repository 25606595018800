<template>
  <div class="ProjectList" :class="{'filterExpanded': filterExpanded != null}"  @wheel="slide($event.deltaY);">
    <transition-group name="list">
      <ProjectStripe v-for="(project,index) in filteredProjects" v-on:openProject="openProject(index)"  :key="project.id" :project="project" :index="index" :projectStatus="projectStatus(index)" :slide="slide" @mouseenter="mouseOver(index)" @mouseleave="mouseOut()"/>
    </transition-group>
    <transition name="resetAnimation" v-if="!activeStudent">
      <div class="errorText" v-if="filteredProjects.length == 0">
        <h1 class="errorText1">Sorry, wir konnten zu<br>deiner Auswahl nichts finden.</h1>
        <span class="errorText2">Klicke auf das<span class="x">×</span>neben den Filtern, um diese zurückzusetzen!</span>
        <div class="filter-cross crossActive" @click="resetFilters()" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" style="
          opacity: 1;
          pointer-events: all;
          width: 30px;"/>
        <!-- <h1 class="errorText2"  @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">Filter zurücksetzen</h1> -->
      </div>
    </transition>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import ProjectStripe from '@/Home/components/ProjectStripe.vue';
export default {
  components: {ProjectStripe},
  data: () => ({
    activeProject: 0,
    listview: true,
    maximumScrollHeight: 0,
    y0: null,
    thresholdMet: false,
    offset: 0,
	}),
  computed: {
    ...mapGetters(['filterExpanded','projects','filteredProjects','detailFilters','projectOpened','landscape','activeStudent','activeCategory','activeFilters']),
    
    filteredProjects() {
      if(this.activeStudent)
        return (this.projects.filter(project => project.students.find(student => student.name == this.activeStudent)));
      return this.projects.filter(this.filterProject);
      },
  },
  watch: {
    filteredProjects(){
      if(this.activeProject != 0)
        this.activeProject = this.filteredProjects.findIndex(project => project == this.filteredProjects[this.activeProject]); //find active Project at changed index
    },
    projectOpened() {
      let projectList = document.getElementsByClassName('ProjectList')[0];
      if(this.projectOpened){
        this.setFilterExpanded(null);
        this.scrollToProject(true);
        projectList.addEventListener('touchstart',this.lock, false);
        projectList.addEventListener('touchend',  this.move, false);
        projectList.addEventListener('touchmove', this.drag, false);
      }
      else{
        this.closeProject();
        projectList.removeEventListener('touchstart',this.lock, false);
        projectList.removeEventListener('touchend',  this.move, false);
        projectList.removeEventListener('touchmove', this.drag, false);
      }
    },
    activeProject() {
      if(this.projectOpened){
        console.log("activeProject")
        this.setProjectOpened(this.filteredProjects[this.activeProject]);
      }
    },
    landscape(){
      if(this.projectOpened){
        let element = document.getElementsByClassName("Home")[0];
        element.style.marginTop= 0;
        element.style.marginLeft= 0;
        element.style.width= '100%';
        element.style.height= '100vh';
        console.log('mobiledesktop')
        this.scrollToProject(false);
      }
    },
  },
  methods: {
    ...mapMutations(['setFilterExpanded','setActiveCategory','setCurrentDetailFilterAtIndex','changeCursor','setProjectOpened','setActiveStudent']),
    resetFilters(){
      for (let i = 0; i < this.detailFilters.length; i++) 
        this.setCurrentDetailFilterAtIndex({'index': i , 'detailFilter': null});
      this.setActiveCategory(null);
    },
    filterProject(project){
      if(this.activeCategory && !project.categories.find(category => category.id == this.activeCategory.id))
        return false;
      let include = true;
      this.activeFilters.forEach((filter,i) => {        
        let details = Object.entries(project).find(entry => entry[0] == this.detailFilters[i].collectionName)[1];
        if(details && filter != null){
          if(!this.detailFilters[i].doubleSlider){
            if(!details.find(detail => detail.title == this.detailFilters[i].options[filter].title))
              include = false;
          }
          else {
            let index = this.detailFilters[i].options.findIndex(option => option.title == details.title)
            if(!(filter[1] >= index && index >= filter[0]))
              include = false;
          }
        }
      })
      return include
    },
    projectStatus(index){
      if(this.activeProject!=index){
        if((this.activeProject+1 == index || this.activeProject-1 == index) && this.projectOpened)
          return 3
        else
          return 0
      }
      else if(this.projectOpened)
        return 2
      else
        return 1
    },    

    // Plays scroll-animation  
    // direction = -100->left-scroll, 100->right-scroll
    // distance = Scrolldistance (in pixels) 
    // duration = duration of scroll animation (in ms)
    slide(direction,distance=7,duration=300){
      if(direction == 0)
        return;
            
      distance*= direction > 0 ? 1 : -1;
      let startTime = new Date().getTime();
      let landscape = this.landscape
      scrolling();
      function scrolling() {
        let t = new Date().getTime() - startTime;
        if(t < duration){
          t /= duration;
          if(landscape)
            document.getElementById("app").scrollBy((1 - Math.sin(t * Math.PI/2)) * distance,0);
          else 
            document.getElementById("app").scrollBy(0,(1 - Math.sin(t * Math.PI/2)) * distance);
          requestAnimationFrame(scrolling);
        }
      }
    },
    scrollToProject(animate){
      let element = document.getElementsByClassName("Home")[0];
      let wrapper = document.getElementById("app");
      if(this.listview){
        if(this.landscape) {
          this.maximumScrollHeight = wrapper.scrollWidth-wrapper.offsetWidth;
          element.style.marginTop = 0
          element.style.marginLeft = -wrapper.scrollLeft+'px'
          wrapper.scrollLeft=0
        }
        else {
          this.maximumScrollHeight = wrapper.scrollHeight-wrapper.offsetHeight;
          element.style.marginTop = -wrapper.scrollTop+'px'
          element.style.marginLeft = 0
          wrapper.scrollTop=0
        }
        element.style.overflow = 'hidden';
      }
      if(animate)
        element.classList.add("scrollanimation");
      this.listview = false;
      setTimeout(() => {
        if(this.landscape) {
          let position = 80*(this.activeProject-1) + 4
          element.style.marginLeft= -position + 'px';
          element.style.width = 'calc(100vw + '+ position + 'px)';
        }
        else {
          let position = 48*(this.activeProject-1);
          element.style.marginTop= -position + 'px';
          element.style.height = 'calc(var(--app-height) + '+ position + 'px)'; //so you cant scroll further
          // window.scrollTo(0, 48*(this.activeProject-1), 'smooth');
        }
      }, 1);
      setTimeout(() => {
        element.classList.remove("scrollanimation")
        // document.body.style.overflow='hidden'
      }, 800); //4000
    },
    closeProject(){
      let element = document.getElementsByClassName("Home")[0];
      let projectscrollby = this.maximumScrollHeight/(this.filteredProjects.length-1);
      element.classList.add("scrollanimation");
      this.listview = true;
      if(this.landscape) {
        element.style.marginLeft = projectscrollby*this.activeProject*-1+'px';
        element.style.width = '';
        if(this.activeStudent)//hacky
          element.style.marginLeft = 0;
      }
      else{
        element.style.marginTop = projectscrollby*this.activeProject*-1+'px';
        element.style.height = '';
      }
      setTimeout(() => {
        element.classList.remove("scrollanimation")
        element.style.overflow= 'initial';
        if(this.landscape) {
          element.style.marginLeft = 0;
          document.getElementById("app").scrollLeft = projectscrollby*this.activeProject;
        }
        else{
        element.style.marginTop = 0;
        document.getElementById("app").scrollTop = projectscrollby*this.activeProject;
        }
      }, 800); //4000
    },
    mouseOver(index){
      if(!this.projectOpened){        
        setTimeout(() => {          
          this.changeCursor( this.activeProject==index && !this.projectOpened ? 'pointer' : 'special-pointer');
        }, 10);
        this.tiemoutID = setTimeout(() => {
          this.activeProject=index;
        }, 400);
      }
    },
    mouseOut(){
      clearTimeout(this.tiemoutID);
      this.changeCursor('default');
    },
    openProject(index){
      if(this.activeProject!=index){
        console.log('too fast!!')
        this.activeProject=index;
      }
      else{
        if(!this.projectOpened){
          this.setProjectOpened(this.filteredProjects[this.activeProject]);
          console.log('set project to opened')
        }
      }
    },

    lookForProject(){
      if(this.$route.params.id) {
        let allStudents = [];
        this.projects.forEach(project => {
          project.students.forEach(student => {
            allStudents.push(student);
          });
        });
        //look if student is in Url
        if(allStudents.find(student => this.$route.params.id && student.name.toLowerCase().replaceAll(' ','-') == this.$route.params.id.toLowerCase().replaceAll(' ','-'))){
          this.setActiveStudent(this.$route.params.id.replaceAll('-',' '))
        }
        else {
          let index = this.projects.findIndex(project => project.title.replaceAll(' ','-') == this.$route.params.id.replaceAll(' ','-'));
          if(index != -1){
            this.activeProject = index,
            console.log('initialSearch');
            this.setProjectOpened(this.filteredProjects[this.activeProject]);
          }
        }
      }
      else{
        this.setProjectOpened(null);
      }
    },
    lock(e){
      let description = document.getElementsByClassName("pr-description")[0];
      if (description.contains(e.target) && description.scrollHeight > description.clientHeight) return;
      this.y0 = this.landscape? e.changedTouches[0].clientX : e.changedTouches[0].clientY;
      document.getElementsByClassName('ProjectList')[0].classList.add('noTransition');      
    },
    drag(e) {
      if(this.y0 == null) return
      e.preventDefault();
      if(this.y0 || this.y0 === 0)
      if(this.landscape){
        let value = Math.round(e.changedTouches[0].clientX - this.y0);
        if(value>100||value<-100){
          document.getElementsByClassName("Home")[0].style.setProperty('--ty', value/4+'px')
          document.getElementsByClassName("Home")[0].style.setProperty('--drag', value)
        }        
      }
      else{
        let value = Math.round(e.changedTouches[0].clientY - this.y0);
        if(!this.thresholdMet && (value>50 || value<-50)){
          this.thresholdMet = true;
          this.offset = value;
        }
        if(this.thresholdMet){
          document.getElementsByClassName("Home")[0].style.setProperty('--ty', (value-this.offset)/4+'px')
          document.getElementsByClassName("Home")[0].style.setProperty('--drag', (value-this.offset)/3)
        }
      }
    },
    move(e) {
      if(this.y0) {
        document.getElementsByClassName('ProjectList')[0].classList.remove('noTransition');
        document.getElementsByClassName("Home")[0].style.setProperty('--ty', '0px');
        document.getElementsByClassName("Home")[0].style.setProperty('--drag', '0');
        this.thresholdMet = false;
        let dy = this.landscape ? e.changedTouches[0].clientX - this.y0 : e.changedTouches[0].clientY - this.y0;
        if(dy<-200)
          this.activeProject++;
        else if (dy>200)
          this.activeProject--;
        this.y0 = null
      }
    },
  },
  created(){
    window.addEventListener("keydown", function(e) {
      if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
      }
    }, false);
    window.addEventListener('scroll', (e) => {
      this.setFilterExpanded(null);
    });
    // onscroll = (event) => { };  

    // document.addEventListener("wheel", event => {
    //   this.activeProject = this.activeProject + Math.sign(event.deltaX);
    // });

    document.onkeyup = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          if(this.landscape && this.activeProject >= 0){
            this.activeProject--;
            this.changeCursor('default');
          }            
          break;
        case 'ArrowUp':
          if(!this.landscape && this.activeProject >= 0){
            this.activeProject--;
            this.changeCursor('default');
          }
          break;
        case 'ArrowRight':
          if(this.landscape && this.activeProject <= this.filteredProjects.length){
            this.changeCursor('default');
            this.activeProject++;
          }
          break;
        case 'ArrowDown':
          if(!this.landscape && this.activeProject <= this.filteredProjects.length){
            this.changeCursor('default');
            this.activeProject++;
          }
          break;
        case 'Escape':
          this.setProjectOpened(null);
          this.changeCursor('default');
          break;
        case 'Enter':
          this.setProjectOpened(this.filteredProjects[this.activeProject]);
          this.changeCursor('default');
          break;
      }
    }
  },
  mounted(){
    this.lookForProject();
    window.onpopstate = (e) => { //browser backbutton
      this.lookForProject();
    };
  },
}
</script>

<style>
.ProjectList{
  background: var(--darkMuted);
  scrollbar-width: none;
  transform-origin: top left;  
  padding-bottom: 65px;
  box-sizing: border-box;
}
.landscape .ProjectList {  
  transform: rotate(-90deg) translate(calc(-100% + 60px),0);
  width: 100vh;
  min-height: 100vw;
  margin-top: -4px;
  padding-bottom: 0;
  padding-top: 156px;
}
.landscape .ProjectList-projectOpened{
  transform: rotate(-90deg) translate(-100%,0);
}
.portrait .ProjectList{
  padding-top: 120px;
  padding-bottom: 65px;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.pr-list:hover .project-title, #pos0 .project-title{
  color: var(--lightVibrant);
}
.collapsed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--globalDarkVibrant);
  transition: opacity 1s, background-color 1s ease;
  pointer-events: all;
  z-index: 1;
}
#pos0 .collapsed{
  background-color: var(--darkVibrant);
}
.collapsed:hover {
  opacity: .5;
}

.scrollanimation .ProjectList{
  transition: padding-top var(--transitonSpeed), transform var(--transitonSpeed);
}
.ProjectList.filterExpanded {
  padding-top: 316px;
}

.landscape .ProjectList.filterExpanded {
  /* margin-left: 436px; */
  min-height: calc(100vw - 436px);
}
.ProjectList-projectOpened {
  padding-top: 0 !important;
}

.scrollanimation {
  transition: transform var(--transitonSpeed), height var(--transitonSpeed), margin-top var(--transitonSpeed), margin-left var(--transitonSpeed) !important;
}
.pl-scrollbar{
  display: inline;
}
.errorText{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  line-height: 1.1;
  padding: 50px;
  box-sizing: border-box;
}
.landscape .errorText{
  font-size: 30px;
  writing-mode: tb;
}

.errorText1{
  color: var(--lightVibrant);
  margin-bottom: 10px;
  font-size: 18px;
}
.landscape .errorText1{
  font-size: 30px;
}
.errorText2{
  color: var(--Vibrant);
  font-size: 16px;
}
.landscape.errorText2{
  font-size: 20px;
  margin: 5px;
}
.crossActive{
  opacity: 1;
  pointer-events: all;
  width: 30px;
}
.crossActive.filter-cross:after, .crossActive.filter-cross:before {
  border-left: 5px solid var(--Vibrant);
}

/* Filter Animation */
.list-enter-active, .list-leave-active {
  transition: height 5s ease;
}
.list-enter-from,.list-leave-to {
  height: 0px !important;
  width: 100% !important;
}
/* Reset Animation */
.resetAnimation-enter-active {
  transition: .8s opacity .3s ease, .7s margin .3s ease; /* 1s delay - waiting for projectstripes animation */
}
.resetAnimation-leave-active {
  transition: opacity .3s ease, margin .3s ease; /* 1s delay - waiting for projectstripes animation */
}
.resetAnimation-enter-from, .resetAnimation-leave-to {
  opacity: 0;
  margin-top: 30px;
}
.x{
  font-size: x-large;
}
</style>