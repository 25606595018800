<template>
  <div class="pr-main-content">
    <!-- Gallery -->
    <div :id="'mySwiper'+projectIndex" class="swiper mp-gallery" >
      <div class="swiper-wrapper">
        <div class="swiper-slide" id="main-image" />
        
        <!-- Images/Videos -->
        <div class="swiper-slide" v-for="(image,index) in project.pictures.slice(1)" :key="image.id">
          <img v-if="image.url.split('.')[1] == 'jpg' || image.url.split('.')[1] == 'webp' || image.url.split('.')[1] == 'png'" @load="this.scale(image)"  class="mp-slide-img"
          :srcset="'https://cvdesign.hshl.de/api/'+ image.url +' 1000w, https://cvdesign.hshl.de/api/' + image.formats.thumbnail.url +' 223w'" sizes="(max-width: 200px) 223px, 1000px" :src="'https://cvdesign.hshl.de/api/'+ image.formats.thumbnail.url"
          onload = "this.style.backgroundColor = 'transparent'"
          :alt="project.title + 'image' + index">

          <img v-else-if="image.url.split('.')[1] == 'gif'" onload = "this.style.backgroundColor = 'transparent'" class="mp-slide-img" :src="'https://cvdesign.hshl.de/api/'+ image.url" alt="">
          
          <Video v-else :src="'https://cvdesign.hshl.de/api/'+image.url" :active="imageIndex == index+1" :id="'image'+index"/>
        </div>

        <!-- Website -->
        <div class="swiper-slide" v-if="project.website" > <!-- @mousemove="changeCursor('default')" @mousemove="checkCursor($event)  -->
          <div class="pr-website-container"> <!-- v-if="navigator.userAgent != 'iphone'" -->
            <img v-if="!project.website.isPrototype" class="pr-mockup" :src="'./Assets/VideoMockups/Macbook.webp'" alt="">
            <iframe class="pr-website" :src="'/'+project.website.url" frameborder="0" @mouseover="changeCursor('none')" @mouseleave="changeCursor('default')" :style="{pointerEvents: imageIndex == project.pictures.length ? 'all' : 'none'}"/>
          </div>
          <button @mouseover="changeCursor('pointer')" @mousemove="changeCursor('pointer')" @mouseleave="changeCursor('default')">
            <a :href="'/'+project.website.url" target="_blank">Seite in neuem Tab öffnen</a>
          </button>
        </div>

        <!-- 3D Viewer -->
        <Project3DViewer v-if="project.Model" class="swiper-slide" :project="project" :visible="imageIndex == this.project.pictures.length + this.project.website" :key="project.id"/>

        <!-- Description -->
        <div class="swiper-slide">
          <div class="pr-description">
            <div class="pr-description-head">
              <div>
                <h1 v-if="landscape" class="pr-description-title">{{project.title}}</h1>
                <h2 class="pr-students"><!-- students -->
                  <span v-for="(student,index) in project.students" :key="student.id">
                    <span style ="white-space: nowrap">
                      <span v-if="(index == project.students.length-1)"> &amp; </span>
                      <span class="mp-text-link" @mousemove="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="showStudent(student.name)">{{student.name}} </span>
                    </span>
                    <span v-if="(index < project.students.length-2)">, </span>
                  </span>
                </h2>
              </div>
              <div v-if="project.logo" class ="logo">
                <img :src="'https://cvdesign.hshl.de/api/' + project.logo.url" alt="logo">
              </div>
            </div>
            <p class="pr-description-text" id="description"/> <!-- description -->
            <div class="projectdetrails">
              <!-- categorys -->
              <div class="pr-description-categories">
                <span v-for="(category) in project.categories" :key="category.id" class="pr-description-category-link" @mousemove="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="linkToCategory(category)">{{category.title+' '}}</span>
              </div>
              <div class="pr-description-details">
                <p class="pr-description-professors">Entstanden
                  <span v-if="project.years"> im Jahr </span>
                  <span class='mp-text-link' @mousemove="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="linkToFilter(2, project.years.title)" v-if="project.years">{{project.years.title}} </span> <!-- TOOD: Remove v-if when all projects have year and semester -->
                  <span> im </span>
                  <span class='mp-text-link' @mousemove="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="linkToFilter(3, project.semesters.title)" v-if="project.semesters">{{project.semesters.title}}. Semester </span> <!-- TOOD: Remove v-if when all projects have year and semester -->
                </p>
                <p class="pr-description-professors">  <!-- Professors -->
                  <span v-if="project.module">„{{project.module.title}}“</span>
                  bei
                  <span v-for="(professor,index) in project.professors" :key="professor.id">
                    <span :class="professor.showInContact ? 'mp-text-link' : '' " @click="professor.showInContact ? linkToProfessor(professor) : ''" @mousemove="professor.showInContact ? changeCursor('pointer') : ''" @mouseleave="changeCursor('default')" style="white-space:nowrap;">{{professor.prefix + ' ' + professor.title}}</span>
                    <span>{{(index == project.professors.length-1 ? "":" & ")}}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProjectPagination :project="project" :imageIndex="imageIndex" :slideTo="setImageIndex"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {marked} from 'marked';
import Swiper from 'swiper';
// import { Swiper } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import 'swiper/swiper.min.css'

import ProjectPagination from '@/Project/ProjectPagination.vue'
import Video from '@/Project/Video.vue'

// import Project3DViewer from '@/Project/Project3DViewer.vue';
// const Project3DViewer = () => import('@/Project/Project3DViewer.vue');

import { defineAsyncComponent } from 'vue'

const Project3DViewer = defineAsyncComponent(() =>
  import('@/Project/Project3DViewer.vue')
)


export default {
  components: { ProjectPagination, Video, Project3DViewer },

  props: ['project','projectIndex'],
  data: () => ({
    imageIndex: 0,
    imageScaling: [],
    description: null,
    swiper : null,
    delay: 200,
    lastClick: 0
  }),
  watch:{
    $route(){ //reset project page when leaving
      this.imageIndex = 0;
      this.projectSlide.getElementsByClassName("project-hero-image")[0].classList.add("active");
    },
    landscape(){
      this.swiper.changeDirection();
    },
  },
  computed: {
    ...mapGetters(['currentColors','detailFilters','categories','landscape']),
    projectSlide(){
      return document.getElementById(this.projectIndex);
    },
  },
  methods: {
    ...mapMutations(['changeCursor','setCurrentDetailFilterAtIndex','setActiveCategory','routerHistory','setProjectOpened','setActiveStudent']),

    linkToCategory(category){
      this.resetFilters();
      this.setActiveCategory(category); // this.categories.find(categoryFilter => categoryFilter.title.toLowerCase() == category.toLowerCase())
      this.setProjectOpened(null)
    },

    linkToProfessor(professor){
      this.resetFilters();
      this.setCurrentDetailFilterAtIndex({'index': 1 , 'detailFilter': this.detailFilters[1].options.findIndex(option => option && option.title.toLowerCase() == professor.title.toLowerCase())}); // set professor detail filter
      this.setProjectOpened(null) // move to projectlist
    },

    linkToFilter(filterIndex, filterValue){
      this.resetFilters();
      let value = this.detailFilters[filterIndex].options.findIndex(option => option && option.title == filterValue);
      this.setCurrentDetailFilterAtIndex({'index': filterIndex , 'detailFilter': [value,value]});
      this.setProjectOpened(null) // move to projectlist
    },

    resetFilters(){
      this.setActiveStudent(null)
      this.setActiveCategory(null); // reset category filter
      for (let i = 0; i < this.detailFilters.length; i++) // reset detail filters
        this.setCurrentDetailFilterAtIndex({'index': i , 'detailFilter': null});
    },

    showStudent(student){
      this.setActiveStudent(student)
      this.setProjectOpened(null);
    },

    setImageIndex(index){
      this.swiper.slideTo(index);
    },
    scale(image){
      // console.log(image.naturalWidth)
    }
  },

  created(){
    this.description = marked.parse(this.project.description);
    for (let i = 0; i < 20; i++){
      this.imageScaling.push({
        top: 50 + (Math.random() * 10 * (Math.random() < 0.5 ? -1 : 1))+"%",
        left: 50 + (Math.random() * 10 * (Math.random() < 0.5 ? -1 : 1))+"%"
      });
    }
  },
  
  mounted(){
    let description =  this.projectSlide.getElementsByClassName("pr-description")[0];
    this.projectSlide.getElementsByClassName("pr-description-text")[0].innerHTML = this.description; // Parse and set project description
    this.projectSlide.addEventListener("wheel", event => {
      if (this.lastClick >= (Date.now() - this.delay))
        return;
      this.lastClick = Date.now();
      if(event.deltaY>0)
        this.swiper.slideNext();
      else
        this.swiper.slidePrev();
    });


      description.addEventListener('wheel',      e => {if(description.scrollHeight > description.clientHeight)e.stopPropagation()});  //hacky dont scroll when description long
      // description.addEventListener('touchstart', e => {if(description.scrollHeight > description.clientHeight)e.stopPropagation()});
      // description.addEventListener('touchend',   e => {if(description.scrollHeight > description.clientHeight)e.stopPropagation()});
      // description.addEventListener('touchmove',  e => {if(description.scrollHeight > description.clientHeight)e.stopPropagation()});

    

    // this.projectSlide.addEventListener("keyup", (e) => {
    //   switch (e.key) {
    //     case 'ArrowUp':
    //     console.log("tgdhrg");
    //       break;
    //     case 'ArrowDown':
    //       break;
    //   }
    // });

    let heroImage = this.projectSlide.getElementsByClassName("project-hero-image")[0];

    // Create swiper
    this.swiper = new Swiper('#mySwiper'+this.projectIndex,{
      noSwipingClass: 'p3-noswipe',
      direction: this.landscape ? 'vertical' : 'horizontal',
      mousewheel: false,
      pagination: {
        el: '.swiper-pagination',
      },
    });
    this.swiper.on('slideChange', () => {
      this.imageIndex = this.swiper.activeIndex;
      if(this.swiper.activeIndex  == 0)
        heroImage.classList.add("active");
      else
        heroImage.classList.remove("active");
    });
    // this.swiper.on('progress', (e) => {
    //   console.log(e);
    //   if(!this.landscape)
    //     heroImage.style.transform = 'translate(calc('+ (-10*e.progress) +'% - 25%),-50%)';
    // });
  },
}
</script>

<style>

.pr-main-content, .background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.pr-clickdummy {
  width: 100% !important;
  height: 182% !important;
  transform: translate(-50%, -50%) scale(43%) !important;
  left: 50%!important;
  top: 50%!important;
}
.pr-website-container{
  max-height: 80%;
  position: relative;
  box-sizing: border-box;
}
.pr-website {
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: scale(0.25) translate(-50%,-54%);
  background-color: #fff;
  aspect-ratio: 16 / 10;
  transform-origin: top left;
}
.portrait .pr-website {
  height: 319%;
  transform: scale(0.25) translate(-50%,-54%);
}
.landscape .pr-website{
  height: 81%;
  transform: scale(1) translate(-50%,-53%);
}

.pr-mockup{
  width: 100%;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.pr-canvas-container{
  width: 60vw;
  height: 37.7vw;
}
.pr-description-categories, .pr-description-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.portrait .pr-description-categories {
  flex-direction: row;
}
.pr-description{
  max-width: 700px;
  background: var(--lightVibrant);
  text-align: left;
  /* line-height: 1.4; */
  z-index: 1;
  pointer-events: all;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background .2s ease;
  border-radius: 2px;
  color: #000;
  padding: 30px 25px;
  box-sizing: border-box;
  overflow: scroll;
  scrollbar-width: none; /*firefox*/
}

.portrait .pr-description {
  font-size: 14px;
}

.projectdetrails {
  display: flex;
  justify-content: space-between;
}
.portrait .projectdetrails {
    flex-direction: column;
}
p.pr-students {
  margin: 6px 0;
  line-height: 1.3;
}
.pr-description ul {
  padding-inline-start: 15px;
  margin: 0;
  list-style: none;
}
.pr-description ul li {
  margin-bottom: 0.3em;
  line-height: 1.2em;
}
.pr-description ul li::before {
  content: "\2022";
  color: var(--darkMuted); 
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.pr-description h1 {
  font-size: 1em;
  font-weight: 600;
  margin-block-start: 15px;
  margin-block-end: 5px;
  color: var(--darkMuted);
    margin-block-start: 1em;
    margin-block-end: 0.3em;
}

.landscape .pr-description{
  width: calc(100% - 168px);
  padding: 60px 50px;
}
.logo{
  width: 30%;
  max-width: -webkit-fill-available;
  position: relative;
  min-height: 20px;
}
.portrait .logo {
  width: 40%;
}
.logo img{
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  right: 0;
}
h1.pr-description-title{
  color: var(--darkVibrant);
  font-size: 35px;
  /* font-weight: 600; */
  margin: 0;
  margin-bottom: 10px;
  line-height: 0.9;
  font-family: 'Urbanist';
  font-variation-settings: 'wght' 700;
}

.portrait h1.pr-description-title {
  font-size: 11vw;
}
.landscape h1.pr-description-title{
  font-size: 85px;
}
h2.pr-students {
  font-size: 1em;
  margin: 0;
  line-height: 1.1;
}

.pr-description-category-link{
  background: var(--darkVibrant);
  color: var(--lightVibrant);
  border-radius: 50px;
  font-weight: 100;
  padding: 5px 18px;
  padding-top: 7px;
  font-size: 13px;
  align-self: baseline;
  margin-right: 15px;
  margin-top: 5px;
  white-space: nowrap;
  transition: background-color .4s;
}
.portrait .pr-description-category-link {
    margin: 0 0 15px 15px;
}
.pr-description-category-link:hover {
  background: var(--darkMuted);
}
.pr-description-category{
  color: var(--darkMuted);
  font-size: 20px;
  transition: color .2s ease;
}
.pr-description-text, .pr-description-professors{
  margin-bottom: 0;
  color: #000;
  font-family: 'Poppins', sans-serif;
  line-height: 1.4;
  white-space: initial;
  font-weight: 100;
}
.pr-description-professors {
    font-size: .9em;
    font-style: italic;
    margin-top: 4px;
    text-align: end;
}
.pr-description-text {
  margin: 40px 0;
}
.portrait .pr-description-text{    
  margin: 20px 0;
}
.portrait .pr-description-text p {
  margin: 10px 0px;
}
.pr-description-professor {
  text-decoration: underline;
}
.pr-description-professor:hover{
  color: var(--Vibrant);
  transition: color .2s ease;
}
.pr-description-head {
  position: relative;
  display: flex;
  column-gap: 10px;
}
/* .portait .pr-description-head {
  grid-template-columns: minmax(min-content, 80%) auto;
} */

.titlewrapper {
  display: table-caption;
}

/* Image overlay Animation
.overlay-enter-from,.overlay-leave-to {
  opacity: 0;
}
.overlay-enter-active, .overlay-leave-active {
  transition: opacity .2s ease, transform .2s ease-out;
}
.overlay-enter-to,.overlay-leave-from {
  opacity: 1;
} */

.mp-gallery{
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
}
.landscape .mp-gallery {
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: top left;
  width: calc(100vw - 2*var(--stripeHeight));
  height: calc(100vh - var(--studentHeight));
}

.swiper-slide{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 15vw 10px 50px 10px;
  box-sizing: border-box;
}
.landscape .swiper-slide{
  padding: 7vh 130px;
}
.mp-slide-img{
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
  object-fit: contain;
  /* background-color: var(--darkMuted); */
}

.mp-video{
  border-radius: 5px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.mp-website-link{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}
button{
  padding: 10px 35px;
  border-radius: 10px;
  background: var(--lightVibrant);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 35px;
  border: none;
}
button a {
    color: var(--darkVibrant);
    font-size: 18px;
    text-decoration: none;
    font-family: 'Poppins';
}
.mp-website-mockup{
  width: 90%;
  position: relative;
}

.mp-text{
  color: #000;
  width: 85%;
  text-align: left;
  background-color: var(--lightVibrant);
  padding: 15px;
  border-radius: 5px;
  position: relative;
  max-height: calc(100% - 130px);
  overflow: scroll;
  font-size: 12px;
  font-weight: bold;
  white-space: break-spaces;
  margin-bottom: 0;
  margin-top: 20px;
  color: #000;
  font-family: 'Poppins', sans-serif;
  line-height: 1.4;
}
.mp-text-link{
  text-decoration: underline;
  white-space:nowrap;
  color: var(--darkVibrant);
}

/* Image overlay Animation */
.overlay-enter-active, .overlay-leave-active {
  transition: opacity .4s ease;
}
.overlay-enter-from,.overlay-leave-to {
  opacity: 0;
}
@media only screen and (min-device-pixel-ratio: 1) {

  img {
    transform: scale(.75);
  }
}
</style>