<template>
  <div class="ProjectPagination">
    <!--image-->
    <svg class="pp-img" viewBox="2 2 20 20" v-for="(image,index) in projectImages" :key="index" @click="slideTo(index)" :class="{'pp-active': imageIndex == index}" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <path :d="image.url.split('.')[1] != 'mp4' && image.url.split('.')[1] != 'webm' ? 'M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z' : 'M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,16.5v-9l6,4.5L10,16.5z'" ></path>
    </svg>

    <!--Clickdummys-->
    <svg class="pp-img" viewBox="2 2 20 20" v-if="project.website" @click="slideTo(projectImages.length)" :class="{'pp-active': imageIndex == projectImages.length}" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <g>
        <path d="M10,9.2c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v1.5c0.4-0.4,0.6-0.9,0.6-1.5c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1 c0,0.6,0.2,1.1,0.6,1.5V9.2z"/>
        <path d="M15.1,13.9l-2.1-1.1h-1.1V9.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v6.5l-2.6-0.5l2.2,2.2c0,0,0.1,0.1,0.1,0.1h3.8 l0.5-2.9C15.4,14.3,15.3,14.1,15.1,13.9z"/>
        <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.6,14.8l-0.5,2.9c-0.1,0.6-0.6,1-1.2,1h-3.8 c-0.4,0-0.7-0.2-1-0.4L7,15.1l0.9-0.9C8,14,8.3,13.9,8.7,13.9c0.1,0,0.1,0,1.4,0.3v-2c-1.1-0.6-1.8-1.7-1.8-3 c0-1.8,1.5-3.4,3.4-3.4c1.8,0,3.4,1.5,3.4,3.4c0,1.1-0.5,2-1.3,2.6l2,1C16.3,13.2,16.7,14,16.6,14.8z"/>
      </g>
    </svg>

    <!--3D-->
    <svg class="pp-img" viewBox="2 2 20 20" v-if="project.Model" @click="slideTo(projectImages.length+prWebsite)" :class="{'pp-active': imageIndex == projectImages.length+prWebsite}" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <polygon points="12,7.4 8.6,9.3 12,11.3 15.3,9.3 "/>
      <polygon points="8,14.3 11.4,16.2 11.4,12.4 8,10.4 "/>
      <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.2,14.2c0,0.5-0.3,1-0.7,1.2l-3.9,2.2 c-0.4,0.2-1,0.2-1.4,0l-3.9-2.2c-0.4-0.2-0.7-0.7-0.7-1.2V9.8c0-0.5,0.3-1,0.7-1.2l3.9-2.2c0.2-0.1,0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2 l3.9,2.2c0.4,0.2,0.7,0.7,0.7,1.2V14.2z"/>
      <polygon points="12.6,16.2 16,14.3 16,10.4 12.6,12.4 "/>
    </svg>

    <!--info-->
    <svg class="pp-img" viewBox="2 2 20 20" @click="slideTo(projectImages.length+prWebsite+prModel)" :class="{'pp-active': imageIndex == projectImages.length+prWebsite+prModel}" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z"></path>
    </svg>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    props: ['project','imageIndex','slideTo'],
    computed: {
      ...mapGetters(['landscape']),
      projectImages(){
        return this.project.pictures;
      },
      prModel(){
        return this.project.Model ? 1 : 0;
      },
      prWebsite(){
        return this.project.website ? 1 : 0;
        // return !this.landscape ? (this.project.website && this.project.website.displayOnMobile ? 1 : 0) : this.project.website ? 1 : 0;
      }
    },
    methods: {
      ...mapMutations(['changeCursor'])
    },
}
</script>

<style scoped>
.ProjectPagination{
  position: absolute;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: var(--stripeHeight);
  align-items: center;
}
.landscape .ProjectPagination{
  flex-direction: row-reverse;
}
.pp-img{
  width: 28px;
  height: 28px;
  margin: 0 15px;
  pointer-events: all;
  border-radius: 100%;
  box-shadow: 0 0 45px #0008;
}
.landscape .pp-img {
  transform: rotate(90deg);
}
path, polygon, g {
  fill: var(--Vibrant);
  transition: fill .4s;
}
.pp-active path, .pp-active polygon, .pp-active g {
  fill: var(--lightVibrant);
}
@media only screen and (max-width: 500px) {
  .ProjectPagination{
    justify-content: space-evenly;
  }
  .pp-img{
    margin: 0;
    width:23px;
    height: 23px;
  }
}
</style>