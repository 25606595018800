<template>
  <section :id="index" class="ProjectStripe" :class="{'closed':projectStatus==0, 'enlarged': projectStatus==1,'opened': projectStatus==2, 'closed':projectStatus==3,}">
    <div class="project-title-wrapper" @click="this.setProjectOpened(null)" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <div class="pr-cross"/>
      <h1 class="project-title">{{project.title}}</h1>
    </div>
    <div class="project-content">
      <div class="projectwrapper" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">   <!-- v-on:click="$emit('openProject')" -->
        <img class="project-hero-image active" :src="this.projectPalette!=null ? 'https://cvdesign.hshl.de/api/'+project.pictures[0].url : 'https://cvdesign.hshl.de/api/'+project.pictures[0].formats.thumbnail.url" :alt="project.pictures.name">
        <div class="vignette"/>
        <ProjectContentGallery v-if="projectStatus==2" :project="project" :projectIndex="index" @mouseenter="changeCursor('default')"/>
      </div>
      <!-- <div class="pr-students" v-if="landscape">
        <p class="pr-name" v-for="(student) in students" :key="student.id" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="showStudent(student.name)">{{student.name}}</p>
      </div> -->
    </div>
    <div class="clickarea" v-on:click="$emit('openProject')" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"></div>/>
  </section>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
import ProjectContentGallery from '@/Project/ProjectContentGallery.vue'
import * as Vibrant from 'node-vibrant'

export default {
  props: ['project','index','projectStatus'],
  components: {ProjectContentGallery},
  data: () => ({
    status: 0, // 0 = closed, 1 = enlarged, 2 = opened, 3 = ready
    tiemoutID: null,
    projectPalette: null
	}),
  computed: {
    ...mapGetters(['projectOpened','currentColors','landscape','highContrast','activeStudent']),
    projectSlide(){
      return document.getElementById(this.index);
    },
    students(){
      if(this.project)
        return this.project.students;
    },
  },
  watch: {
    projectStatus(){
      switch (this.projectStatus){
      case 1:
        this.generateColorScheme();     
        break;
      case 2:   
        this.generateColorScheme();
        if(this.projectPalette!=null && this.projectPalette.Vibrant!=null &! this.highContrast)
          this.setCurrentColors([this.projectPalette.Vibrant.hex,this.projectPalette.LightVibrant.hex,this.projectPalette.DarkMuted.hex,this.projectPalette.DarkVibrant.hex]);
        setTimeout(() => {
          // this.projectSlide.classList.add('noTransition')
        }, 400);
        break;
      case 3: 
        setTimeout(() => {
          this.generateColorScheme();
        }, 1500);
      }
    },
    projectPalette(){
      if(this.projectStatus==2 && !this.highContrast && this.projectPalette.Vibrant!=null)
        this.setCurrentColors([this.projectPalette.Vibrant.hex,this.projectPalette.LightVibrant.hex,this.projectPalette.DarkMuted.hex,this.projectPalette.DarkVibrant.hex]);
    }
  },
  methods: {
    ...mapMutations(['changeCursor','changeRedirectToProject','setCurrentColors','setProjectOpened','setActiveStudent']),

    generateColorScheme(){
      if(this.projectPalette!=null)
        return;
      console.log('generating '+this.project.title)
      Vibrant.from('https://cvdesign.hshl.de/api/' + this.project.pictures[0].formats.thumbnail.url).getPalette().then((palette) => {
        this.projectPalette=palette;
          if(!this.highContrast&& palette.Vibrant!=null){
            this.projectSlide.style.setProperty('--Vibrant',  palette.Vibrant.getHex());
            this.projectSlide.style.setProperty('--lightVibrant',palette.LightVibrant.getHex());
            // this.projectSlide.style.setProperty('--lightMuted',palette.LightMuted.getHex());
            this.projectSlide.style.setProperty('--darkMuted', palette.DarkMuted.getHex());
            this.projectSlide.style.setProperty('--darkVibrant', palette.DarkVibrant.getHex());
            this.projectSlide.style.setProperty('--hue', palette.DarkMuted.getHsl()[0]*360+'deg');
            this.projectSlide.style.setProperty('--saturation', palette.DarkMuted.getHsl()[1]);
          }
      })
    },
    showStudent(student){
      this.setActiveStudent(student)
      this.setProjectOpened(null);
    },
  },
  created(){
    if(this.projectStatus==1 && this.projectPalette==null)
      this.generateColorScheme(); 
  }
}
</script>

<style scoped>
.clickarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ProjectStripe {
  position: relative;
  overflow: hidden;
  background-color: var(--globalDarkVibrant);
  color: var(--globalLightVibrant);
  border-top: 4px solid var(--globalDarkMuted);
  margin-top: -4px;
  width: 100%;
  height: var(--stripeHeight);
  display: block;
  --studentHeight: 0px; /*60*/
  transition: height var(--transitonSpeed), background-color var(--transitonSpeed), color var(--transitonSpeed);
}
.pr-students{
  display: none;
  justify-content: center;
  background-color: var(--darkMuted);
  transition: background .5s ease;
  font-size: 18px;
  writing-mode: tb;
  z-index: 3;
}

.pr-name{
  text-decoration: underline;
  margin: 50px 0;
  line-height: var(--studentHeight);
  transition: color .2s ease;
  color: transparent;
  pointer-events: none;
  white-space: nowrap;
}
.pr-name:hover {
  color: var(--Vibrant);
}
.opened .pr-students .pr-name{
  pointer-events: all;
  color: var(--lightVibrant);
}
@media screen and ( max-height: 720px ) { 
  .pr-students{
    display: none;
  }
  .ProjectStripe {
    --studentHeight: 0px;
  }
}
/* .project-title-wrapper:hover .pr-cross {
  transform: rotate(90deg);
} */
.opened .pr-cross{
  opacity: 1;
  pointer-events: all;
  margin-left: 4%;
}
.projectwrapper {
  position: relative;
  width: inherit;
  background-color: var(--darkMuted);
}
.landscape .ProjectStripe {
  left: -4px;
}
.ProjectStripe:last-child {
  border-bottom: 4px solid var(--darkMuted);
}
/* .ProjectStripe:hover ,.ProjectStripe.opened{
  background-color: var(--darkMuted);
} */

.project-content{
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0;
  display: flex;
  flex-direction: row-reverse;
  transition: opacity .4s;
  padding-bottom: 4px;
  box-sizing: border-box;
}

.opened .project-hero-image.active{
  opacity: 1;
}

.ProjectStripe.closed .pr-students{
  color: transparent;
  pointer-events: none;
}

.project-hero-image {
  height: 100%;
  width: 100%;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  object-fit: cover;
  border-radius: 0;
  transition: opacity .4s;
}
/* .portrait .opened .project-hero-image {
  left: 0;
  transition: opacity .4s, transform .3s;
  width: unset;
} */
.portrait .noTransition .project-hero-image, .portrait .scrollanimation .project-hero-image{
  transition: none;
}

.vignette {
  position: absolute;
  top: 0;
  left: -100%;
  width: 400%;
  height: 70vw;
  background: radial-gradient(#fff 40%, var(--darkMuted));
  pointer-events: none;
  opacity: .75;
  mix-blend-mode: multiply;  
}

.scrollanimation .vignette{
  transition: width .4s, height .4s, left .4s;
}

.portrait .vignette {
  height: calc(100vh - 2*var(--stripeHeight));
}

.ProjectList-projectOpened .vignette{
  width: 200%;
  left: -50%;
  height: calc(100vw - 2*var(--stripeHeight));
}
.portrait .ProjectList-projectOpened .vignette {
  height: calc(100vh - 2*var(--stripeHeight));
}

.pr-lightBox{
  width: 100%;
  height: 100%;
  background: var(--darkMuted);
  transition: background .2s ease;
  opacity: 0;
  position: absolute;
  /* mix-blend-mode: multiply; */
  pointer-events: none;
  z-index: 1;
  top: 0;
  transition: opacity .4s;
}

.project-title-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
  font-size: min(6vh, 45px);
  margin-top: 10px;
}
.portrait .project-title-wrapper {
  font-size: min(6vw, 22px);
  margin-top: 9px;
}
.opened .project-title-wrapper {
  pointer-events: all;
}
/* .landscape .project-title-wrapper {
  margin-left: 60px;  /* navbar-height 
} */
.landscape .opened .project-title-wrapper {
  font-size: 10vh;
}
.portrait .opened .project-title-wrapper {
  font-size: 10vw;
}

.project-title {
  margin: 0;
  margin-left: 10px;
  box-sizing: border-box;
  transition: right .5s ease;
  z-index: 1;
  font-size: 1em;
  padding: 0;
  font-family: Urbanist;
  font-variation-settings: "wght" calc(300 + max(var(--drag), 0));
  transition: font-size .4s, font-variation-settings .4s, color .4s;
  letter-spacing: 1.5px;
}
.opened ~ .closed .project-title{
  font-variation-settings: "wght" calc(300 - min(var(--drag), 0));
}
.noTransition .project-title{
  transition: none;
}
.landscape .project-title{
  /* font-size: min(6vh, 50px); */
}
.enlarged .project-title {
  font-variation-settings: "wght" 600;
}
.opened .project-title {
  font-variation-settings: "wght" 700;
  line-height: 1;
}
h2.pr-students {
  font-size: 1em;
  font-weight: 400;
}
.enlarged .project-content{
  opacity: 1;
}
@media (hover: hover) and (pointer: fine) {
  .Home:not(.scrollanimation) .closed:hover .project-content {
    opacity: 1 !important;
  }
  .closed:hover .project-title{
    color: var(--lightVibrant);
  }
}

.image-fade-enter-from, .image-fade-leave-to {
  opacity: 0;
}
.image-fade-enter-active, .image-fade-leave-active {
  transition: opacity .5s;
}
.image-fade-enter-to, .image-fade-leave-from {
  opacity: 1;
}
.portrait .ProjectStripe.opened{
  height: calc(var(--app-height) - 2*var(--stripeHeight) - env(safe-area-inset-bottom) + 4px + min(var(--ty), -1*var(--ty)));
}
.ProjectList-projectOpened .project-hero-image{
  opacity: .25;
}
.portrait .ProjectList-projectOpened .project-hero-image{
  height: calc(var(--app-height) - 2*var(--stripeHeight) - env(safe-area-inset-bottom) + 4px);
}
.noTransition .ProjectStripe{
  transition: none !important;
}
.closed{
  height: calc(var(--stripeHeight) + max(var(--ty), 0px));
}
.opened ~ .closed{  
  height: calc(var(--stripeHeight) - min(var(--ty), 0px));
}
.closed .project-content {
  opacity: calc(var(--drag)/300);
}
.opened ~ .closed .project-content {
  opacity: calc(var(--drag)/-300);
}
.opened .project-content{
  opacity: calc(1 - max(var(--drag)/500, -1*var(--drag)/500));
}
.noTransition .project-content{
  transition: none;
}
.scrollanimation .closed{
  transition: height var(--transitonSpeed), background-color var(--transitonSpeed), color var(--transitonSpeed);
}
.landscape .ProjectStripe.opened{
  height: calc(100vw - 2*var(--stripeHeight) + 4px + min(var(--ty), -1*var(--ty)));
}
.ProjectStripe.enlarged{
  height: 30vw;
}
.landscape .ProjectStripe.enlarged{
  height: 500px;
}
.delay-enter-active, .delay-leave-active {
  transition: .6s opacity .4s ease;
}
.delay-enter, .delay-leave-to {
  opacity: 0;
}

.landscape .project-hero-image {
  transform-origin: top left;
  transform: rotate(90deg) translate(-50%,-50%);
  width: calc(100vw - 2*var(--stripeHeight));
  height: calc(100vh - var(--studentHeight)) !important;
}
.noTransition{
  transition: none;
}
</style>
