<template>
  <nav>
    <div class="Navbar">
      <div class="na-links">
        <router-link to="/">
          <h1 class="na-link-text" @mouseenter="$route.name != 'list' ? changeCursor('pointer'): ''" @click="setActiveStudent(null)" @mouseleave="changeCursor('default')" :class="$route.name == 'list' ? 'na-link-text-primary' : 'na-link-text-hover'">Projekte</h1>
        </router-link>
        <router-link to="/Kontakt">
          <h1 class="na-link-text" @mouseenter="$route.name != 'contact' ? changeCursor('pointer'): ''" @mouseleave="changeCursor('default')" :class="$route.name == 'contact' ? 'na-link-text-primary' : 'na-link-text-hover'">Kontakt</h1>
        </router-link>
      </div>
      <div class="na-inclusion" v-if="landscape">
        <a class="na-inclusion-text" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" :href="email">Barriere Melden</a>
        <a class="na-inclusion-text" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="toggleHighContrast()" :class="{'active' : highContrast}">Hoher Kontrast</a>
        <router-link to="/Impressum" class="na-inclusion-text" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">Impressum</router-link>
        <router-link to="/Datenschutz" class="na-inclusion-text" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">Datenschutz</router-link>
      </div>
      <div class="na-burger" v-else @click="toggleMenue()">
        <span class="na-line" v-for="i in 3" :key="i"/>
      </div>
    </div>
    <div class="na-background" @click="$event.stopPropagation(); toggleMenue();" :style="{ 
      opacity: showMenue ? .5 : 0,
      pointerEvents: showMenue ? '' : 'none'
    }"/>
    <div class="na-menue" @click="$event.stopPropagation()" :style="{
      bottom: (showMenue ? '60px' : '-176px')
    }">
      <a class="na-menue-inclusion" :href="email">Barriere Melden</a>
      <p class="na-menue-inclusion" @click="toggleHighContrast()" :class="{'active' : highContrast}">Hoher Kontrast</p>
      <router-link to="/Impressum" class="na-menue-inclusion" :style="{ color: $route.name == 'imprint' ? 'var(--Vibrant)' : ''}" @click="toggleMenue()">Impressum</router-link>
      <router-link to="/Datenschutz" class="na-menue-inclusion" :style="{ color: $route.name == 'imprint' ? 'var(--Vibrant)' : ''}" @click="toggleMenue()" >Datenschutz</router-link>
    </div>    
  </nav>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';

export default {
  data: () => ({
    email: "mailto: info@cvdesign.hshl.de?subject=Barrieremeldung&body=Guten%20Tag%2C%0D%0A%0D%0Aauf%20der%20Website%20www.cvdesign.hshl.de%20m%C3%B6chte%20ich%20eine%20Barriere%20melden.%0D%0A%0D%0A%3E%3E%3E%20Bitte%20hier%20eine%20Beschreibung%20der%20Barriere%20und%20die%20URL%20der%20Webseite%20einf%C3%BCgen.%20%3C%3C%3C",
    showMenue: false
  }),
  computed: {
    ...mapGetters(['landscape','currentColors','oldColors','highContrast']),
  },
  methods: {
    ...mapMutations(['changeCursor','setCurrentColors','setOldColors','setHighContrast','setActiveStudent']),
    toggleHighContrast(){
      this.setHighContrast(!this.highContrast);
      // console.log(this.highContrast);
      this.setOldColors(this.currentColors);
      this.setCurrentColors(this.highContrast ? ['#00bbff','#fff','#000','#cd4700'] : ['#b8df41','#d4eb8d','#2b5a5c','#144c44']);
    },
    toggleMenue(){
      this.showMenue = !this.showMenue;
    }
  }
}
</script>

<style scoped>
nav {
  position: fixed;
    z-index: 2;
    height: 60px;
    width: 100%;
    bottom: 0;
    left: 0;
    transition: transform .5s;
}
.Navbar{
  display: flex;
  background-color: var(--lightVibrant);
    height: 100%;
    align-items: center;
    z-index: 2;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    transition: background-color .4s;
}
.Navbar-projectOpened{
  transform: translateY(60px);
}

.Navbar a{
  text-decoration: none;
}
.na-links{
  font-size: 18px;
}
.na-link-text{
  margin: 0;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  z-index: 1;
  color: var(--darkMuted);
}
.na-link-text:after{    
  background: none repeat scroll 0 0 transparent;
  bottom: 0%;
  content: "";
  display: block;
  height: 3px;
  left: -10%;
  z-index: 0;
  position: absolute;
  transition: top;
  width: 120%;
  transition: bottom .2s ease, opacity .2s ease;
  background: var(--darkMuted);
  opacity: 0;
}
.na-link-text-hover:hover:after{ 
  bottom: -30%;
  opacity: 1;
}
.na-link-text-primary{
  font-weight: 600;
  color: var(--darkVibrant);
}
.na-link-text-primary:after{
  background: var(--Vibrant);
}
.na-inclusion {
  text-align: right;
}
.na-inclusion-text{
  color: black;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  transition: color .2s ease;
  margin-left: 20px;
}
.router-link-active.na-inclusion-text , .na-inclusion-text.active {
  font-weight: 600;
}

/* Motile */
.na-line{ 
  height: 4px;
  width: 30px; 
  background: var(--darkMuted); 
  border-radius: 1px;
  display: block; 
  margin-bottom: 4px;
  position: relative;
}
.na-line:nth-child(3){
  margin-bottom: 0px;
}
.na-page{
  width: calc(100vw - 120px);
  text-align: center;
  margin: 0%;
  font-size: 20px;
  color: var(--darkMuted);
}
.na-background{
  height: 100vh;
  width: 100vw;
  transition: opacity .4s ease;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
}
.na-menue{
  width: 100vw;
  position: fixed;
  left: 0;
  transition: bottom .4s ease, top .4s ease;
  background: var(--darkMuted);
  padding: 30px;
  box-sizing: border-box;
  text-align: right;
  display: flex;
  flex-direction: column;
}
.na-menue-link{
  margin: 0;
  margin-left: 80px;
  font-size: 20px;
  margin-top: 10px;
  color: var(--lightVibrant);
}
.na-menue-inclusion{
  margin-top: 15px;
  color: var(--lightVibrant);
}
</style>