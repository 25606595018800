<template>
  <div class="Contact">
    <Navbar/>
    <div class="ct-content">
      <!-- Professoren Section -->
      <div class="ct-section">
        <div class="ct-professor" v-for="professor in professors" :key="professor.id">
          <img class="ct-professor-image gradientImage" :src="'https://cvdesign.hshl.de/api/'+professor.picture.url" alt="">
          <div class="ct-professor-info">
            <h3 class="ct-professor-name">{{professor.prefix + ' ' + professor.title}}</h3>
            <p class="ct-professor-subject">
              <span v-if="professor.subject || professor.categories.length > 0">Schwerpunkt in </span>
              <span v-if="professor.subject" style="white-space: nowrap;">{{professor.subject}}, </span><br>
              <span v-for="(categoty,i) in professor.categories" :key="categoty.id">
                <span v-if="professor.categories.length>1 && i == professor.categories.length-1">{{" und "}}</span>
                <span class="ct-professor-subject-link" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" @click="linkToSubject(categoty.title)">{{categoty.title}}</span>
                <span v-if="professor.categories.length>1 && i < professor.categories.length-2">{{", "}}</span>
              </span>
            </p>
            <a class="ct-professor-email" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')" :href="'mailto:'+professor.email">{{professor.email}}</a>
          </div>
        </div>
      </div>

      <div class="ct-line"/>

      <!-- Studiengang Section -->
      <div class="ct-section">
        <div class="cvd-vid-container">
          <img class="gradientImage cvd-vid-thumbnail" :src="'./Assets/thumbnail.png'" alt="" @click="startVideo()">
          <button class='cvd-play'/>
          <Video id="cvd-vid" class="cvd-vid" :src="'./Assets/cvd.mp4'" :active="playVideo" :style="{filter: currentColors[0] == '#ffffff' ? 'grayscale(100%)' : ''}"/>
        </div>
        <div>
          <a class="ct-info1" href="https://www.hshl.de/studieren/studiengaenge/bachelorstudiengaenge/computervisualistik-und-design/" target="_blank" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">Du bist interessiert?</a>
        </div>
      </div>
    </div>
    <!-- SVG Filters -->
    <div class="ct-filters"/>
  </div>
</template>

<script>
import {mapMutations,mapGetters} from 'vuex';
import axios from 'axios';
import Video from '@/Project/Video.vue'
// const Video = () => import('@/Project/Video.vue');
import Navbar from '@/components/Navbar.vue'


export default {
  components: {Navbar,Video},
  data: () => ({
    professors: null,
    playVideo: false
	}),
  computed: {
    ...mapGetters(['projects','categories','detailFilters','currentColors', 'highContrast']),
  },
  watch: {
    currentColors(){
      this.duoToneContact();
    }
  },
  mounted: function(){
    axios.get("https://cvdesign.hshl.de/api/professors").then((response) => {
      this.professors = response.data.filter(professor => professor.showInContact);
      console.log(this.professors);
      console.log(this.professors[1].Subject);
      setTimeout(() => { this.duoToneContact(); }, 10);     // timeout until dom updated and crated images of profs
    });

    
  },
  methods: {
    ...mapMutations(['setActiveCategory','setCurrentDetailFilterAtIndex','changeCursor']),
    linkToSubject(subject){
      // reset filters
      for (let i = 0; i < this.detailFilters.length; i++)
        this.setCurrentDetailFilterAtIndex({'index': i , 'detailFilter': null});
      // ChangeFilter
      this.setActiveCategory(this.categories.find(categoryFilter => categoryFilter.title.toLowerCase() == subject.toLowerCase()));
      this.$router.push({name: 'list'});
    },
    startVideo(){
      document.getElementById("cvd-vid").style.display = 'block';
      document.getElementsByClassName("cvd-play")[0].style.display = "none";
      document.getElementsByClassName("cvd-vid-thumbnail")[0].style.display = "none";
      this.playVideo=true;
      document.getElementsByTagName("video")[0].muted = false;
    },
    duoToneContact(){
      if(this.highContrast) return;
      // Remove old svg filters
      document.getElementsByClassName("ct-filters")[0].innerHTML = "";
      
      // Add svg filters
      var gradientImages = document.getElementsByClassName("gradientImage");
      for (var i = 0; i < gradientImages.length; i++) {
        let hex = this.calcHex();
        this.createSvgFilters(hex,i);
        gradientImages[i].style.filter = 'url(#duotone_'+ i +')';
      };
    },
    calcHex(){
      let rootStyle = document.querySelector(':root').style;
      let hex1 = rootStyle.getPropertyValue('--lightVibrant').replace("#",'');
      let hex2 = rootStyle.getPropertyValue('--darkMuted').replace("#",'');
      if(this.getLuma(hex1) > this.getLuma(hex2))
        return hex1+","+hex2;
      return hex2+","+hex1;
    },
    getLuma(hex){
      var rgb = parseInt(hex, 16);  
      var r = (rgb >> 16) & 0xff;
      var g = (rgb >>  8) & 0xff; 
      var b = (rgb >>  0) & 0xff;
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luma;
    },
    hexToRgb(hex) {
      var bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r,g,b];
    },
    duotone (hexes) {
      var hex = hexes.split(',');
      var color1 = this.hexToRgb(hex[0]);
      var color2 = this.hexToRgb(hex[1]);
      
      var c1   = {};
      c1.red   = color1[0] / 256;
      c1.green = color1[1] / 256;
      c1.blue  = color1[2] / 256;
      
      var c2   = {};
      c2.red   = color2[0] / 256;
      c2.green = color2[1] / 256;
      c2.blue  = color2[2] / 256;
      
      var value = [];
      value = value.concat([c1.red - c2.red, 0, 0, 0, c2.red]);
      value = value.concat([c1.green - c2.green, 0, 0, 0, c2.green]);
      value = value.concat([c1.blue - c2.blue, 0, 0, 0, c2.blue]);
      value = value.concat([0, 0, 0, 1, 0]);

      return value.join(' ');
    },
    createSvgFilters (hex,i) {
      let ns = 'http://www.w3.org/2000/svg';
      let svg = document.createElementNS(ns,'svg');
      let filterEl = document.createElementNS(ns,"filter");
      let matrix = document.createElementNS(ns,"feColorMatrix");
  
      svg.setAttribute('style','color-interpolation-filters: sRGB');
      svg.style.width = "0";
      svg.style.height = "0";
      filterEl.setAttribute('id','duotone_' + i);
      matrix.setAttribute('type', 'matrix');
      matrix.setAttribute('values', this.duotone(hex));
    
      document.getElementsByClassName("ct-filters")[0].appendChild(svg).appendChild(filterEl).appendChild(matrix);
      return;
    },
  }
}
</script>

<style scoped>
.ct-filters {
    display: none;
}
input.timeline {
  width: 100%;
}
.Contact.landscape{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.Contact.portrait {
  padding-bottom: 60px;
}



.landscape .ct-content{
  font-family: 'Poppins', sans-serif;
  display: flex;
  height: calc(100vh - 60px); /* Height of content = page size - navbar size - imprint size */
  background: var(--darkVibrant);
}
.portrait .ct-content{
  display: block;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.ct-section{
  color: var(--lightVibrant);
  margin: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}
.landscape .ct-section:first-child {
  max-width: 65vh;
}
.ct-section-title{
  font-size: 40px;
  font-weight: normal;
}

.ct-professor {
  display: flex;
  height: 30%;
  /* height: 40%; */
}
.ct-professor-image{
  border-radius: 3px;
  height: 100%;
}
.ct-professor-name{
  font-size: 7vh;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 0.9;
    font-family: 'Urbanist';
    font-variation-settings: 'wght' 600;
}
.ct-professor-info{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ct-professor-email{
  display: block;
  margin: 0;
  font-weight: 500;
  color: var(--lightVibrant);
  text-decoration: underline;
  transition: color .2s ease;
}
.ct-professor-email:hover{
  color: #fff;
}
.ct-professor-subject{
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.1;
  margin: 0;
}
.ct-professor-subject-link{
  text-decoration: underline;
  transition: color .2s ease;
}
.ct-professor-subject-link:hover{
  color: var(--lightVibrant);
}
.ct-professor-subject span{
  display: inline;
}
.ct-line{
  display: inline-block;
  border-left: 10px solid var(--darkMuted);
}
.cvd-vid-container{
  position: relative;
  height: 70%;
  width: fit-content;
  height: fit-content;
  max-height: 80%;
}
.cvd-play{
  pointer-events: none;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  border-style: solid;
  background: transparent;
  border-color: transparent transparent transparent var(--darkMuted);
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-width: 25px 0px 25px 50px;
  transition: border-color 0.2s ease;
  padding: 0;
  border-radius: 0;
  margin: 0;
}
.cvd-vid-container:hover .cvd-play{
  border-color: transparent transparent transparent var(--lightVibrant);
}
.cvd-vid-thumbnail{
  max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.cvd-vid{
  margin-top: 20px;
  display: none;
  width: 100%;
  border-radius: 5px;
}
.ct-info1{
  font-size: 6vw;
  line-height: 0.9;
  font-weight: bold;
  font-family: 'Urbanist';
  font-variation-settings: 'wght' 100;
  color: var(--lightVibrant);
  text-decoration: none;
  transition: font-variation-settings .5s;
}
a.ct-info1:hover {
  font-variation-settings: 'wght' 600;
}

.ct-link:hover {
  color: var(--Vibrant); 
}

.slide-down-leave-active{
  z-index: 9;
}

.portrait .ct-section{
  width: calc(100% - 30px);
  margin: 0px;
  padding: 70px 15px;
  gap: 15px;
}
.portrait .ct-section:first-child {
  height: 131vw;
}
  .portrait .ct-professor-info{
    width: 100%;
    padding-left: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 3.5vw;
  }
  .portrait .ct-professor-name{
    font-size: 9vw;
  }
  .portrait .ct-professor-email{
    margin-bottom: 3px;
  }
  .portrait .ct-professor-image{
    margin-right: 20px;
  }
  .portrait .ct-line{
    width: 100vw;
    margin-top: 20px;
    display: block;
    border-top: 3px solid var(--darkMuted);
    border-left: none;
  }
  .portrait .ct-info1{
    text-align: center;
    font-size: 18vw;
  }
  .portrait .ct-info2{
    text-align: center;
  }
  .portrait .cvd-vid-thumbnail{
    width: 100%;
  }

@media only screen and (max-width: 400px) {
  .ct-professor-image{
    margin-right: 10px;
  }
  .ct-professor-email{
    margin-bottom: 10px;
  }
}
</style>
