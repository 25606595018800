<template>
  <div class="FilterBar">
    <CategoryFilter :changeFilterTitleSize="changeFilterTitleSize" :expanded="filterExpanded==0" v-on:toggleSlideout="toggleSlideout(0)"/>
    <DetailFilter   :changeFilterTitleSize="changeFilterTitleSize" :expanded="filterExpanded==1" v-on:toggleSlideout="toggleSlideout(1)"/>
  </div>
</template>

<script>
import CategoryFilter from '@/Home/components/Filter/CategoryFilter.vue'
import DetailFilter from '@/Home/components/Filter/DetailFilter.vue'
import {mapGetters,mapMutations} from 'vuex';

export default {
  components: {CategoryFilter,DetailFilter},
  computed: {
    ...mapGetters(['filterExpanded']),
  },
  methods: {
    ...mapMutations(['setFilterExpanded']),
    changeFilterTitleSize(index){
      let filterTitle = document.getElementsByClassName("filter-title")[index];
      let filterTitleText = document.getElementsByClassName("filter-title-text")[index];
      if(filterTitle){
        filterTitle.style.width = filterTitleText.offsetWidth + 1 + "px";
      }
    },
    toggleSlideout(index){
      let element = document.getElementsByClassName("Home")[0];
      element.classList.add("scrollanimation")
      this.setFilterExpanded(this.filterExpanded == index ? null : index);
      console.log(this.filterExpanded);
      setTimeout(() => {
        element.classList.remove("scrollanimation")
      }, 400);
    },
  },
  watch: {
    // categoryFilterTitle(){
    //   setTimeout(() => { this.changeFilterTitleSize(); }, 0);
    // },
    landscape(){
      this.changeFilterTitleSize(0);
      this.changeFilterTitleSize(1);
    }
  },
  mounted(){
    this.changeFilterTitleSize(0);
    this.changeFilterTitleSize(1);
  }
}
</script>

<style>
.FilterBar{
  background: var(--lightVibrant);
  color: var(--darkMuted);
  width: 100%;
  position: fixed;
  z-index: 2;
  top:0;
  left: 0;
  white-space: nowrap;
  transform-origin: top left;
  user-select: none;
  font-family: 'Urbanist';
  font-variation-settings: "wght" 500;
}
.landscape .FilterBar{
  transform: rotate(-90deg) translate(-100%,0);
  width: 100vh;
  position: absolute;   /* ? */
}
.portrait .scrollanimation .FilterBar{
  transition: top .4s;
}
.landscape .scrollanimation .FilterBar{
  transition: left .4s;
}
.portrait .FilterBar.hidden{
  top: -120px;
}
.landscape .FilterBar.hidden {
  left: -160px;
}

.ProjectFilter {
  height: 100%;
  max-height: 60px;
  overflow-x: hidden;
  display: block !important;
  transition: .4s max-height;
  overflow: hidden;
  border-bottom: 4px solid var(--darkMuted);
  box-sizing: border-box;
  padding: 0 15px;
}
.landscape .ProjectFilter {
  padding-left: 80px;
  max-height: 80px
}
.filter-head{
  transition: background .3s ease;
  display: inline-flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  height: 56px;
}
.landscape .filter-head{
  height: 80px;
}
.filter-title{
  margin: 0;
  text-transform: uppercase;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  transition: color .4s ease, width .4s ease;
  white-space: pre;
}
.landscape .filter-title{
  font-size: 25px;
  line-height: 80px;
}


.filter-arrow {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-right: 15px solid var(--darkMuted); 
  border-radius: 2px;
  transition: transform .4s, border-right-color .4s;
  order: 1;
  margin-left: 15px;
  transform: rotate(-90deg);
}
.filterActive .filter-arrow {
  border-right-color: var(--darkVibrant);
}
.expanded .filter-arrow {
  transform: rotate(90deg)
}
.filterActive .filter-title {
  color: var(--darkVibrant);
  font-weight: 600;
  font-variation-settings: 'wght' 700;
}
.filter-slideout-element{
  height: 49px;
  display: flex;
  position: relative;
  align-items: center;
  transition: margin-left .4s;
  /* justify-content: space-between; */
}
.filter-slideout-element.CategoryFilterActive {
  margin-left: 0;
  color: var(--darkVibrant)!important;
}
.landscape .filter-slideout-element{
  height: 69px;
}


.filter-slideout-title{
  transition: font-variation-settings .4s;
  font-size: 18px;
  margin: 0;
}
.landscape .filter-slideout-title{
  font-size: 22px;
}
.CategoryFilterActive .filter-slideout-title, .slider-selected .filter-slideout-title{
  color: var(--darkVibrant)!important;
  /* font-weight: 600; */
  font-variation-settings: "wght" 800;
}

.filterTextAnimation-enter-active, .filterTextAnimation-leave-active {
  transition: opacity .3s, transform .3s;
  transform: translateX(0);
}
.filterTextAnimation-enter-active{
  display: inline-block;
  opacity: 0.1;
  transform: translateX(-15px);
}
.filterTextAnimation-enter-to{
  display: inline-block;
  opacity: 1;
  transform: translateX(0);
}
.filterTextAnimation-leave-to{
  display: inline-block;
  opacity: 0.1;
  transform: translateX(15px);
}
.filter-cross{
  position: relative;
    transition: opacity .6s,margin .5s,width .5s, transform .3s;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 16px;
}
.filter-slideout-element .filter-cross {
  transform: scale(0.7);
}
.landscape .filter-cross{
  transform: rotate(0);
}
/* .landscape .filter-slideout-element.CategoryFilterActive:hover .filter-cross {
  transform: rotate(90deg);
} */
.CategoryFilterActive .filter-cross, .slider-selected .filter-cross{
  opacity: 1;
  pointer-events: all;
  width: 20px;
}
.landscape .CategoryFilterActive .filter-cross, .landscape .slider-selected .filter-cross{
  width: 30px;
}
.filterActive .filter-head .filter-cross{
  opacity: 1;
  pointer-events: all;
  width: 30px;
}

.filter-cross:after, .filter-cross:before{
  content: '';
    height: 20px;
    border-left: 4px solid var(--darkVibrant);
    position: absolute;
    left: 6px;
    top: -2px;
}
.filter-cross:after{
  transform: rotate(45deg);
}
.filter-cross:before{
  transform: rotate(-45deg);
} 
.filter-background{
  position: absolute;
  pointer-events: none;
  width: 100vh;
  height: 100vw;
  background: var(--darkMuted);
  opacity: 0;
  margin-top: 344px;
  z-index: 20;
  transition: opacity .5s;
}

.filter-background.Expanded{
  pointer-events: all;
  opacity: .5;
} 

.filterTextAnimation-enter-active{
  transform: translateY(-15px);
}
.filterTextAnimation-enter-to{
  transform: translateY(0);
}
.filterTextAnimation-leave-to{
  transform: translateY(15px);
}
</style>
