import {createRouter, createWebHistory} from 'vue-router'

import Home from './Home/Home.vue'
import Contact from './Contact/Contact.vue'
import Imprint from './Imprint/Imprint.vue'
import dataProtection from './Imprint/dataProtection.vue'
import NotFound404 from './NotFound404/NotFound404.vue'


// const Home = () => import('./Home/Home.vue')
// const Contact = () => import('./Contact/Contact.vue')
// const Imprint = () => import('./Imprint/Imprint.vue')
// const dataProtection = () => import('./Imprint/dataProtection.vue')
// const NotFound404 = () => import('./NotFound404/NotFound404.vue')

const routes = [
  {
    path: '/',
    name: 'list',
    component: Home
  },

  {
    path: '/:id',
    name: 'home',
    component: Home
  },

  {
    path: '/Kontakt',
    name: 'contact',
    component: Contact
  },

  {
    path: '/Impressum',
    name: 'imprint',
    component: Imprint
  },

  {
    path: '/Datenschutz',
    name: 'dataProtection',
    component: dataProtection
  },

  // {
  //   path: '/404',
  //   name: "notfound404",
  //   component: NotFound404
  // },
  
  {
    path: '/:catchAll(.*)',
    name: "notfound404",
    component: NotFound404
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
