<template>
  <div class="filter ProjectFilter" :class="{'expanded': expanded, 'filterActive': detailFilterSelected}" :style="{maxHeight: landscape ? (expanded ? 70*detailFilters.length + 95 + 'px' : '') : (expanded ? 50*detailFilters.length + 60 + 'px' : '')}">
    <div class="filter-head" v-on:click="$emit('toggleSlideout')" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">
      <div class="filter-arrow"/>
      <div class="filter-cross" @click="resetFilters()" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/>
      <h1 class="filter-title"><span class="filter-title-text">{{detailFilterSelected ? detailFilterTitle : "Filter"}}</span></h1>
    </div>
    <div v-for="(filter,i) in detailFilters" :key="filter.id" class="filter-slideout-element" :class="{'CategoryFilterActive' : activeFilters[i] != null}">
      <DefaultSlider v-if="!filter.doubleSlider" :index="i" :optionsLength="filter.options.length-1"/>
      <DoubleSlider v-else :index="i" :optionsLength="filter.options.length-1"/>
    </div>
  </div> 
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import DefaultSlider from '@/Home/components/Filter/Sliders/DefaultSlider.vue'
import DoubleSlider from '@/Home/components/Filter/Sliders/DoubleSlider.vue'
export default {
  
  components: {DefaultSlider, DoubleSlider},
  props: ['changeFilterTitleSize','expanded'],
  watch:{
    detailFilterTitle(){
      setTimeout(() => { this.changeFilterTitleSize(1); }, 0);
    },
  },
  computed: {
    ...mapGetters(['detailFilters','activeFilters','landscape']),
    detailFilterSelected(){
      return !this.activeFilters.every(filter => filter === null);
    },
    detailFilterTitle(){
      let title = "";
      this.activeFilters.forEach((filter,i) => {
        if(filter != null){
          // DefaultSlider Title
          if(filter.length != 2){
            title += this.detailFilters[i].options[filter].title;
          }
          // DoubleSlider Title
          else{
            if(filter[0] == 0 && filter[1] == this.detailFilters[i].options.length-1)
              title += this.detailFilters[i].title;
            else if(filter[0] == filter[1])
              title += this.detailFilters[i].options[filter[0]].title + this.detailFilters[i].prefix;
            else  
              title += this.detailFilters[i].options[filter[0]].title + "–" + this.detailFilters[i].options[filter[1]].title + this.detailFilters[i].prefix;
          }
          // Add space between
          if(i != this.detailFilters.length-1 && this.activeFilters[i+1] != null)
            title += "   ";
        }
      });
      return title;
    },
  },
  methods: {
    ...mapMutations(['changeCursor','setCurrentDetailFilterAtIndex']),

    resetFilters(){
      for (let i = 0; i < this.detailFilters.length; i++) 
        this.setCurrentDetailFilterAtIndex({'index': i , 'detailFilter': null});
    }
  },
}
</script>

<style>
.slider{
  position: relative;
  bottom: -10px;
  --slider-connect-bg: var(--darkMuted);
  --slider-radius: 1px;
  --slider-handle-bg: var(--lightVibrant);
  --slider-bg: var(--darkMuted);
  --slider-handle-height: 25px;
  --slider-handle-width: 25px;
  --slider-handle-shadow: 0 0 0 4px var(--lightVibrant);
  --slider-handle-shadow-active: none;
  --slider-height: 5px;
  --slider-vertical-height: 100px;
  --slider-handle-border: 4px solid var(--darkMuted);
  /* --slider-handle-ring-width: 10px;
  --slider-handle-ring-color: var(--darkMuted); */
  }

.slider:hover, .slider-selected, .slider-selected-hover {
  --slider-handle-bg: var(--darkVibrant)!important;
  --slider-handle-border: 4px solid var(--darkVibrant);
}
.double-slider:hover, .double-slider.slider-selected, .double-slider-selected-hover {
   --slider-connect-bg: var(--darkVibrant) !important; 
}
.slider:hover ~ .filter-slideout-title {
  color: var(--darkVibrant);
}
.slider-handle {
  transition: background .2s ease;
}
.slider-connect {
  transition: background .2s ease;
}
.double-slider.slider-selected .slider-connects {
  z-index: 6;
}

</style>

<style scoped>
.filter-slideout-element{
  justify-content: space-between;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>