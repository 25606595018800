import { createStore } from 'vuex'
import Router from './router'
import Home from '@/Home/Home.vue'
import NotFound404 from '@/NotFound404/NotFound404.vue'

export default createStore({
  state: {
    projects: [],

    // Filter
    categories: [],
    detailFilters: [],
    activeCategory: null,
    activeFilters: [],

    projectOpened:null,
    filterExpanded:null,

    routerHistory: [],
    cursor: {type:"default"},
    currentColors: [],
    oldColors: [],
    highContrast: false,
    activeStudent: null,
    landscape: false,
    customFavicon: false,
  },

  getters: {
    activeStudent: (state) => { return state.activeStudent},
    highContrast: (state) => { return state.highContrast},
    landscape: (state) => { return state.landscape},
    customFavicon: (state) => { return state.customFavicon},
    currentColors: (state) => { return state.currentColors},
    oldColors: (state) => { return state.oldColors},
    routerHistory: (state) => { return state.routerHistory},
    activeCategory: (state) => { return state.activeCategory},
    activeFilters: (state) => { return state.activeFilters},
    categories: (state) => { return state.categories;},
    detailFilters: (state) => { return state.detailFilters;},
    projectOpened: (state) => { return state.projectOpened;},
    filterExpanded: (state) => { return state.filterExpanded;},
    cursor: (state) => { return state.cursor;},
    projects: (state) => { return state.projects;},


    
    isRouteProject: (state) => {
      return state.projects.find(project => state.routerHistory[state.routerHistory.length-1].params.id && project.title.replaceAll(' ','-') == state.routerHistory[state.routerHistory.length-1].params.id.replaceAll(' ','-'));
    },
  },
  mutations: {
    changeRedirectToProject(state){
      Router.addRoute({path: '/:id', component: Home});
    },
    // changeRedirectTo404(){
    //   Router.addRoute({path: '/:id', component: NotFound404, name: 'redirect'});
    // },
    setActiveStudent(state, activeStudent){
      state.activeStudent = activeStudent;
    },
    
    setHighContrast(state, highContrast){
      state.highContrast = highContrast;
    },
    setLandscape(state, landscape){
      state.landscape = landscape;
      let app = document.getElementById("app");
      if(landscape)
        app.classList.add("landscape")
      else
        app.classList.remove("landscape")
    },
    setCustomFavicon(state, customFavicon){
      state.customFavicon = customFavicon;
    },    
    setCurrentColors(state, currentColors){
      state.currentColors = currentColors;
    },
    setOldColors(state, oldColors){
      state.oldColors = oldColors;
    },
    updateRouterHistory(state, route){
      state.routerHistory.push(route);
    },
    setActiveCategory(state, activeCategory){
      state.activeCategory = activeCategory;
    },
    setCurrentDetailFilterAtIndex(state, data){
      state.activeFilters[data.index] = data.detailFilter;
    },
    setProjects(state, projects){
      state.projects = projects;
    },
    setcategories(state, filters){
      state.categories = filters;
    },
    setDetailFilters(state, filters){
      state.detailFilters = filters;
      filters.forEach(() => {
        state.activeFilters.push(null);
      });
    },
    setFilterExpanded(state, bool){
      state.filterExpanded = bool;
    },
    
    setProjectOpened(state, project){
      state.projectOpened = project;
      
      if(project){
        console.log(project.title)
        //set tab title        
        document.title = project.title+' | CVDesign';
        //set favicon
        if(project.logo){
          state.customFavicon = true;
          document.querySelector('link[rel="icon"]').href = "https://cvdesign.hshl.de/api/" + project.logo.url;
          console.log('customFavicon')
        }
        else{
          state.customFavicon = false;
        }
        //route
        Router.push({name: 'home', params: {id: project.title.replaceAll(' ','-')}})
      }
      else{
        console.log('noproject')
        document.title = 'CVDesign';
        state.customFavicon = false;
        Router.push(
          state.activeStudent ?
          {name: 'home', params: {id: state.activeStudent.replaceAll(' ','-')}} :
          {name: 'list'}
        );
      }
    },
    changeCursor(state, type){
      state.cursor.type = type;
    },
  },
})