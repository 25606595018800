<template>
  <div class="pr-video-container">
    <p ref="loading">{{src}} lädt...</p>
    <video preload="metadata" playsinline class="pr-image pr-video" ref="video" @click="toggleVideo()" @mouseenter="this.$refs.video.paused ? this.changeCursor('videoPlay') : this.changeCursor('videoPause')" @mouseleave="changeCursor('default')" :src="src" loop muted/> 
    <img class="pr-videoMockup" src="" ref="mockup" alt=""> 
    <!-- <input type="range" min="0" max="100" class="progress-bar timeline" ref="timeline" value="0" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/> -->
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  props: ['src','active'],
  // data: () => ({
  //   hasProgressBar: false
  // }),
  computed: {
    ...mapGetters(['landscape']),
    mockup(){
      return this.$refs.mockup;
    }
  },
  watch:{
    active(){
      let video = this.$refs.video;
      if(this.active){
        var playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
            this.changeCursor('videoPause');
            video.classList.remove("paused");
          })
          .catch(error => {
            // Auto-play was prevented
            this.changeCursor('videoPlay');
            video.classList.add("paused");
          });
        }
      }      
      else{
        video.pause();
        video.currentTime = 0;
      }
    },
    landscape(){
      if(this.active){      
        if (this.landscape) {
          if(this.mockup.style.display=='none'){
            this.$refs.video.requestFullscreen();
            screen.orientation.unlock();
          }
        }
        else if(document.fullscreenElement){
          document.exitFullscreen();
        }
      }
    }
  },
  methods: {
    ...mapMutations(['changeCursor']),
    toggleVideo(){
      let video = this.$refs.video;
      if(video.paused){
        video.play();
        this.changeCursor('videoPause');
        video.classList.remove("paused");
      }
      else{
        video.pause();
        this.changeCursor('videoPlay');
        video.classList.add("paused");
      }
    },

    applyMockup(video){
      let mockup = this.mockup;
      // let progressBar = this.$refs.timeline;
      // progressBar.style.display='none';
      let aspectRatio = video.videoHeight/video.videoWidth;
      video.style.backgroundColor = '#000';


      if (aspectRatio >= 2.14 && aspectRatio <= 2.2) {        // 13/6  > iPhone X  
        mockup.src='./Assets/VideoMockups/iPhone-X.webp';
        video.style.height= '93%'; // video.style.maxWidth=  '86%';
      }
      else if (aspectRatio >= 1.75 && aspectRatio <= 1.80){   // 16/9  > iPhone SE
        mockup.src='./Assets/VideoMockups/iPhone-8.webp';
        video.style.height= '77%';// video.style.width=  '87%';
      }
      else if (aspectRatio >= 1.43 && aspectRatio <= 1.44){   // 23/16 > iPad
        mockup.src='./Assets/VideoMockups/iPad.webp';
        video.style.height= '91.2%';// video.style.width=  '87.8%';
      }
      else if (aspectRatio >= 1.229 && aspectRatio <= 1.234){ // 16/13 > Apple Watch
        mockup.src='./Assets/VideoMockups/Apple-Watch.webp';
        video.style.height= '50.2%';// video.style.width=  '68%';
      }
      else {                                              // > kein Mockup
        video.style.position='relative';
        video.style.backgroundColor = 'transparent';
        video.style.borderRadius= '5px';
        mockup.style.display='none';
        // this.hasProgressBar = true;
        // progressBar.style.display='block';
      }
    }
  },
  mounted(){
    // let timeline = this.$refs.timeline;
    let video = this.$refs.video;
    // video.autoplay = true;
    video.ontimeupdate = (event) => {
      let angle = video.currentTime/video.duration*360;
      if(document.getElementById("circle_right")){
        document.getElementById("circle_right").style.transform ='rotate('+Math.min((-135+angle),  45)+'deg)';
        document.getElementById("circle_left").style.transform  ='rotate('+Math.max((-315+angle),-135)+'deg)';
        document.getElementById("rounding-end").style.transform ='rotate('+angle+'deg) translateY(calc(var(--size)/-2 - 2px))';
      }
      // if(this.hasProgressBar){
      //   let percentagePosition = (100*video.currentTime) / video.duration;
      //   timeline.style.backgroundSize = percentagePosition +'% 100%';
      //   timeline.value = percentagePosition;
      // }      
    };
    
    // video.addEventListener('loadeddata', () => {
    //   if (video.readyState >= 1) {
    //     this.applyMockup();
    //   }
    // });

    video.addEventListener('loadedmetadata', (e) => {
      this.applyMockup(video);
      this.$refs.loading.style.display="none";
    });

 

    // timeline.addEventListener('change', () => {            
    //   const time = (timeline.value * video2.duration) / 100;
    //   video.currentTime = time;
    // });
    
    this.mockup.addEventListener("orientationchange", function(e) {
      var orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
      console.log(orientation);
      if ( ["landscape-primary","landscape-secondary"].indexOf(orientation)!=-1) {
        console.log(this.mockup.src)
        if(this.mockup.src)
          video.requestFullscreen();
          //screen.orientation.unlock();
      }
      else{
        document.exitFullscreen();
      }
    });
  }
}
</script>

<style scoped>
.pr-video-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  max-height: 100%; /* height für Safari?*/
}
.pr-videoMockup{
  position: relative;
  pointer-events: none;
  height: 100%;
  max-width: 100%;
  filter: hue-rotate(var(--hue)) saturate(var(--saturation));
  object-fit: contain;
  -webkit-transform: translate3d(0,0,0);  /* Safari z-index bug */
}
.pr-video{
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  object-fit: contain;
  pointer-events: all;
}
.paused {
  filter: brightness(70%);
}
.timeline{
  position: absolute;
  bottom: 0;
  background-color: var(--darkVibrant);
  transition: height .3s;
  width:100%;
  -webkit-appearance: none;
  height: 4px;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(var(--lightVibrant), var(--lightVibrant));
  background-repeat: no-repeat;
  margin: 0;
}
.timeline:hover {
  height: 8px;
}
.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  opacity: 0;
  transition: all .1s;
  background-color: var(--lightVibrant);
}
.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  opacity: 0;
  transition: all .1s;
  background-color: var(--lightVibrant);
}
.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  opacity: 0;
  transition: all .1s;
  background-color: var(--lightVibrant);
}
.pr-video-container p {
  word-break: break-all;
  max-width: 50%;
}
.timeline::-webkit-slider-thumb:hover {
  background-color: var(--lightVibrant);
}
.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}
.timeline::-moz-range-thumb:hover {
  background-color: var(--lightVibrant);
}
.timeline:hover::-moz-range-thumb {
  opacity: 1;
}
.timeline::-ms-thumb:hover {
  background-color: var(--lightVibrant);
}
.timeline:hover::-ms-thumb {
  opacity: 1;
}
</style>