<template>
    <div class="filtertitle" @click="setCurrentDetailFilterAtIndex({'index': index, 'detailFilter': null})" @mouseenter="activeFilters[index] != null ? changeCursor('pointer') : ''" @mouseleave="changeCursor('default')">
        <div class="filter-cross"/>
        <h1 class="filter-slideout-title" @mouseenter="titleHover(true)" @mouseleave="titleHover(false)">
            <span v-if="sliderValue[0] == 0 && sliderValue[1] == optionsLength" :key="sliderValue.id">{{detailFilters[index].title}}</span>
            <span v-else>
                <span v-if="sliderValue[0] == sliderValue[1]">{{detailFilters[index].options[sliderValue[0]].title}}</span>
                <span v-if="sliderValue[0] != sliderValue[1]">
                    <transition name="filterTextAnimation" mode="out-in">
                        <span :key="sliderValue[0].id">{{detailFilters[index].options[sliderValue[0]].title}}</span>
                    </transition>
                    <span>–</span>
                    <transition name="filterTextAnimation" mode="out-in">
                        <span :key="sliderValue[1].id">{{detailFilters[index].options[sliderValue[1]].title}}</span>
                    </transition>
                </span>
                <span>{{detailFilters[index].prefix+" "}}</span>
            </span>
        </h1>
    </div>
    <div class="Sliderbox">
        <Slider :id="'doubleSlider'+index" class="slider double-slider" v-model="sliderValue" :orientation="landscape ? 'vertical' : 'horizontal'" :direction="landscape ? 'rtl' : 'ltr'" :tooltips="false" :max="optionsLength" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/>
    </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import Slider from '@vueform/slider'

export default {
    components: {Slider},
    props: ['index','optionsLength'],
    computed: {
        ...mapGetters(['detailFilters','activeFilters','landscape']),
        sliderValue: {
            get(){
                // Change filter color when selected
                let slider = document.getElementById("doubleSlider"+this.index);
                let currentDetailFilter = this.activeFilters[this.index];

                if(slider){
                    if(currentDetailFilter != null){
                        if(currentDetailFilter[0] == 0 && currentDetailFilter[1] == this.optionsLength)
                            slider.classList.remove("slider-selected")  
                        else
                            slider.classList.add("slider-selected")
                    }else{
                        slider.classList.remove("slider-selected")  
                    }
                }

                if(currentDetailFilter != null)
                    return [currentDetailFilter[0],currentDetailFilter[1]];
                return [0,this.optionsLength];
            },
            set(value){
                this.setCurrentDetailFilterAtIndex({'index': this.index , 'detailFilter': value[0] == 0 && value[1] == this.optionsLength ? null : value}); 
            }
        }
    },
    methods: {
        ...mapMutations(['setCurrentDetailFilterAtIndex','changeCursor']),
        titleHover(bool){
            let slider = document.getElementById("doubleSlider"+this.index);
            bool ? slider.classList.add("slider-selected-hover") : slider.classList.remove("slider-selected-hover")
        }
    },
}
</script>

<style>
.filtertitle {
  display: flex;
  align-items: center;
  transition: transform .4s
}
.CategoryFilterActive .filtertitle {
  transform: translateX(0);
}
.Sliderbox {
    width: calc(100vw - 190px);
    right: 25px;
    height: 23px;
    padding-right: 12px;
}
.landscape .Sliderbox {
    position: relative;
    height: calc(100vh - 325px);
    width: calc(100vh - 325px);
    transform: rotate(90deg) translate(50%);
    left: 0;
    right: unset;
}

.slider-vertical {
  width: 6px;
  height: 100% !important;
  margin: 0;
}

.double-slider .slider-connects{
    overflow: visible;
}
.landscape .double-slider.slider-selected .slider-connect{
    transition: width .4s, background .2s;
    right: -50%;
    width: 200%;
}
.portrait .double-slider.slider-selected .slider-connect{
    transition: height .4s, background .2s;
    top: -25%;
    height: 150%;
}
</style>