<template>
  <Cursor v-if="!isPhone"/>
  <router-view :class="landscape? 'landscape': 'portrait'" v-slot="{Component}" v-if="dataLoaded">
    <transition :name="transitionName">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
import axios from 'axios';
// import * as Vibrant from 'node-vibrant'

// const Cursor = () => import('@/components/Cursor.vue');
import { defineAsyncComponent } from 'vue'
const Cursor = defineAsyncComponent(() =>
  import('@/components/Cursor.vue')
)
// import Cursor from '@/components/Cursor.vue'

export default {
  components: {Cursor},
  data: () => ({
    transitionName: '',
    dataLoaded: false,
    publicPath: process.env.BASE_URL,
	}),
  computed: {
    ...mapGetters(['landscape','currentColors','customFavicon','projects','projectOpened']),
    isPhone(){
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase());
      // return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
    }
  },
	methods: {
    ...mapMutations(['setLandscape','setProjects',"setcategories","setDetailFilters",'updateRouterHistory','setCurrentColors']),
    async loadProjectsAndFilters(){
      axios.get("https://cvdesign.hshl.de/api/projects").then((response) => {
        this.setProjects(response.data.sort((a, b) => a.description.charAt(0).localeCompare(b.description.charAt(0))));
        // this.configureRedirect();
        this.dataLoaded = true;
      })

      axios.get("https://cvdesign.hshl.de/api/categories").then((response) => {
        this.setcategories(response.data);
      })

      let detailFiltersRespone = await axios.get("https://cvdesign.hshl.de/api/detail-filters");
      let details = detailFiltersRespone.data;

      details.forEach(detail => {
        axios.get("https://cvdesign.hshl.de/api/"+detail.collectionName).then((response) => {
          detail.options = response.data;
          if(detail.collectionName == "professors") //filter professors which shouldnt appear in filter
            detail.options = detail.options.filter(option => option.showInContact);
          if(!detail.doubleSlider)
            detail.options.splice(parseInt(detail.options.length/2), 0, null);
        })
      })

      // TODO: Remove Timeout
      setTimeout(() => { this.setDetailFilters(details); }, 500);
    },
    generateRandomColorScheme(){
      console.log('generated random')
      // this.setCurrentColors(['#b8df41','#d4eb8d','#2b5a5c','#144c44']);

      let primaryColors   = ['#b8df41',"#F45075","#006C67","#2d8722","#2D3142","#D17A22","#F93943","#F5D547"];
      let secondaryColors = ['#d4eb8d',"#CFFA76","#F194B4","#E8E9EB","#BFC0C0","#B4C292","#F7E733","#ff567a"];
      let tertiaryColors  = ['#2b5a5c',"#6600FF","#003844","#1d3f1f","#4F5D75","#4C061D","#2B2D42","#0f347a"];
      let randomNumber = Math.floor(Math.random()*primaryColors.length);
      this.setCurrentColors([primaryColors[randomNumber],secondaryColors[randomNumber],tertiaryColors[randomNumber],this.adjust(tertiaryColors[randomNumber],-50)]);
  

      // let randomNumber = Math.floor(Math.random()*this.projects.length);
      // Vibrant.from('https://cvdesign.hshl.de/api/' + this.projects[randomNumber].pictures[0].url).getPalette().then((palette) => {
      //   this.setCurrentColors([palette.Vibrant.getHex(),palette.LightVibrant.getHex(),palette.DarkMuted.getHex(),palette.DarkVibrant.getHex()]);
      // })
    },
    setMetaColor(){
      let meta1 = document.querySelector('meta[name="theme-color"]');
      let color = this.landscape? this.currentColors[2] : this.projectOpened ? this.currentColors[3] : this.currentColors[1];
      if(!meta1){
        meta1 = document.createElement('meta');
        meta1.name = "theme-color";
        document.getElementsByTagName('head')[0].appendChild(meta1);
      }
      meta1.content = color;

      let meta2 = document.querySelector('meta[name="msapplication-navbutton-color"]');
      if(!meta2){
        meta2 = document.createElement('meta');
        meta2.name = "msapplication-navbutton-color";
        document.getElementsByTagName('head')[0].appendChild(meta2);
      }
      meta2.content = color;

      let meta3 = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
      if(!meta3){
        meta3 = document.createElement('meta');
        meta3.name = "apple-mobile-web-app-status-bar-style";
        document.getElementsByTagName('head')[0].appendChild(meta3);
      }
      meta3.content = color;
    },
    handleIcon(){
      var svg = `<?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
      <style type="text/css">
        .st0{clip-path:url(#mask_00000108303802488093508480000014281501842882773665_);}
        .st1{fill: ` + this.currentColors[0] + `;}
        .st2{fill: ` + this.currentColors[2] + `;}
      </style>
      <g>
        <defs>
          <circle id="mask" cx="8" cy="8" r="8"/>
        </defs>
        <clipPath id="mask_00000142859387226205038600000002839341331754993796_">
          <use xlink:href="#mask"  style="overflow:visible;"/>
        </clipPath>
        <g style="clip-path:url(#mask_00000142859387226205038600000002839341331754993796_);">
          <rect id="bg" class="st1" width="16" height="16"/>
          <g>
            <rect id="s4" x="12" y="0" class="st2" width="1" height="16"/>
            <rect id="s3" x="9" y="0" class="st2" width="1" height="16"/>
            <rect id="s2" x="6" y="0" class="st2" width="1" height="16"/>
            <rect id="s1" x="3" y="0" class="st2" width="1" height="16"/>
          </g>
        </g>
      </g>
      </svg>`

      if(!this.customFavicon)
        document.querySelector('link[rel="icon"]').href = "data:image/svg+xml;base64,"+window.btoa(svg);
    },
    isTouchDevice(){
      return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    },
    adjust(color, amount) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    },
    preloadMockups(){
      let mockupURLS = ["./Assets/VideoMockups/iPhone-X.webp",'./Assets/VideoMockups/iPhone-8.webp','./Assets/VideoMockups/iPad.webp','./Assets/VideoMockups/Apple-Watch.webp',,'./Assets/VideoMockups/Macbook.webp'];
      mockupURLS.forEach(mockupURL => {
        new Image().src = mockupURL;
      });
    },
  },
  watch: {
    $route(to,from){
      this.updateRouterHistory(to);
      // Set page transition animations
      this.transitionName = "";
      if(
        to.name == "imprint" && from.name == "contact" ||
        to.name == "imprint" && from.name == "home" ||
        to.name == "contact" && from.name == "home" ||
        to.name == "contact" && from.name == "list" ||
        to.name == "redirect" && from.name == "home"
      )
      this.transitionName = "slide-down";
      if(
        to.name == "contact" && from.name == "imprint" ||
        to.name == "home" && from.name == "contact" ||
        to.name == "list" && from.name == "contact" ||
        to.name == "home" && from.name == "imprint"
      )
      this.transitionName = "slide-up";
    },
    currentColors(){
      this.handleIcon();
        this.setMetaColor();
        let root= document.querySelector(':root');
        root.style.setProperty('--Vibrant', this.currentColors[0]);
        root.style.setProperty('--lightVibrant', this.currentColors[1]);
        root.style.setProperty('--darkMuted', this.currentColors[2]);
        root.style.setProperty('--darkVibrant', this.currentColors[3]);
        //global Colors
        root.style.setProperty('--globalVibrant', this.currentColors[0]);
        root.style.setProperty('--globalLightVibrant', this.currentColors[1]);
        root.style.setProperty('--globalDarkMuted', this.currentColors[2]);
        root.style.setProperty('--globalDarkVibrant', this.currentColors[3]);
    },
    projectOpened() {
      if(!this.projectOpened)
      this.setMetaColor();
    },
    customFavicon(){
      this.handleIcon();
    },
    // landscape(){
    //   this.landscape ? document.getElementById('app').style.overflow='' : document.getElementById('App').style.overflow='scroll'
    // }
  },
  mounted(){
    this.loadProjectsAndFilters();
    handleWebsiteResize();
    this.preloadMockups();
    this.generateRandomColorScheme();    
    this.setLandscape(window.innerHeight <= window.innerWidth);
    var doit;
    window.addEventListener('resize', (e) => {
      handleWebsiteResize();
      this.setLandscape(window.innerHeight <= window.innerWidth); 
    });
    function handleWebsiteResize(){
      let app = document.getElementById("app");
      app.style.setProperty('--app-height', window.innerHeight+'px');
      app.classList.add('noTransition');      
      clearTimeout(doit);
      doit = setTimeout(() => {app.classList.remove('noTransition')}, 100);
    }
  }
}
</script>

<style>
@font-face {
  font-family: Poppins;
  src: url("/Assets/Fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 100;
  src: url("/Assets/Fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 100;
  font-style: italic;
  src: url("/Assets/Fonts/Poppins-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("/Assets/Fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Urbanist;
  src: url("/Assets/Fonts/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: test;
  src: url("/Assets/Fonts/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}



* {
  /* user-select: none; */
  cursor: none !important;
}
/* html {
  --Vibrant:#b8df41;
  --lightVibrant:#d4eb8d;
  --darkMuted:#2b5a5c;
  --darkVibrant:#144c44;
  --globalVibrant:#b8df41;
  --globalLightVibrant:#d4eb8d;
  --globalDarkMuted:#2b5a5c;
  --globalDarkVibrant:#144c44;
} */
#app {
  overflow: unset;
  --transitonSpeed: .4s;
  --saturation: 2;
  --hue: 30deg;
}
#app.landscape {
  /* height: 100%; */
  overflow-x: scroll;
  overflow-y: hidden;
}
h1 {
  font-weight: 400;
  font-size: 1em;
}
a {
  color: var(--Vibrant);
}
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* user-select: none; */
  margin: 0;
  height:100vh;
  overscroll-behavior-y: contain;
}
html{
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  background-color: var(--darkMuted);
}
::-webkit-scrollbar {
  display: none;
}

/* Disable Chromecast icon */
video::-internal-media-controls-overlay-cast-button {
  display: none;
}

/* Page Transitions */
.slide-up-enter-active, .slide-up-leave-active, .slide-down-enter-active, .slide-down-leave-active{
  transition: left 0.5s;
}
.slide-up-enter-to, .slide-down-enter-to {
  position: absolute;
  left: 0%;
}
.slide-up-leave-from, .slide-down-leave-from{
  position: absolute;
  left: 0%;
}

/* Page slide-up */
.slide-up-enter-from{
  position: absolute;
  left: -100%;
}
.slide-up-leave-to{
  position: absolute;
  left: 100%;
}

/* Page slide-down */
.slide-down-enter-from{
  position: absolute;
  left: 100%;
}
.slide-down-leave-to{
  position: absolute;
  left: -100%;
}
</style>
