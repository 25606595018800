<template>
  <div class="Imprint">
    <Navbar/>       
    <div class="im-section">
      <h1>Impressum</h1>

    <h2>Angaben gemäß $ 5 TMG</h2>
    <p>Hochschule Hamm-Lippstadt<br />
    Studiengang Computervisualistik und Design (CVD)<br />
    Marker Allee 76-78<br />
    59063 Hamm</p>

    <p><strong>Vertreten durch:</strong><br />
    Prof. Rainer Baum<br />
    Prof. Dr. Sven Quadflieg</p>

    <h2>Kontakt</h2>
    <p>E-Mail: <a href="mailto:info@cvdesign.hshl.de" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')">info@cvdesign.hshl.de</a> </p>

    <h2>Redaktionell verantwortlich</h2>
    <p>Prof. Rainer Baum<br />
    Prof. Dr. Sven Quadflieg</p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">e-recht24.de/impressum-generator</a></p>
  </div>
</div>
</template>

<script>
import {mapMutations,mapGetters} from 'vuex';

import Navbar from '@/components/Navbar.vue'

export default {
  components: {Navbar},
  computed: {
    ...mapGetters(['currentColors'])
  },

  methods: {
    ...mapMutations(['changeCursor']),
  },
  mounted: function(){
    document.title = 'Impressum | CVDesign';
  }
}
</script>

<style scoped>
.Imprint{
  overflow: hidden;
}

.im-section {
  font-weight: 500;
  line-height: 1.5;
  font-size: 13px;
  box-sizing: border-box;
  width: calc(100% - 30px);
  max-width: 1000px;
  padding: 30px;
  border-radius: 5px;
  background: var(--Vibrant);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 86px;
}
a {
  color: var(--darkVibrant);
}

h1 {
  font-weight: 800;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 1.1em;
  margin-top: 30px;
  margin-bottom: 2px;
}
td {
  padding: 0;
  padding-right: 5px;
}
</style>
