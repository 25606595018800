<template>
  <div>
    <div class="Home">
      <FilterBar v-if="!activeStudent" :class="{'hidden': projectOpened}"/>
      <div v-else class="st-name" :class="{'hidden': projectOpened}" >
        <div class="pr-cross" @click="setActiveStudent(null)" @mouseenter="changeCursor('pointer')" @mouseleave="changeCursor('default')"/>
        <h1>{{activeStudent}}</h1>
      </div>
      <ProjectList :class="{'ProjectList-projectOpened': projectOpened}"/>
      <Navbar :class="{'Navbar-projectOpened': projectOpened}"/>
    </div>
  </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
import Navbar from '@/components/Navbar.vue'
import ProjectList from '@/Home/components/ProjectList.vue'
import FilterBar from '@/Home/components/Filter/FilterBar.vue'
import NotFound404 from '@/NotFound404/NotFound404.vue'


export default {
  props: ['studentlist'],
  components: {Navbar,ProjectList,FilterBar,NotFound404},
  computed: {
    ...mapGetters(['projectOpened','activeStudent']),
  },
  methods: {
    ...mapMutations(['setActiveStudent','changeCursor']),
  }
}
</script>

<style>
.st-name {
  color: var(--darkVibrant);
  background: var(--lightVibrant);
  width: 100%;
  padding: 0 20px;
  height: 120px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: 4px solid var(--darkMuted);
  transition: margin-top .4s;
  box-sizing: border-box;
  z-index: 1;
}
.portrait .st-name.hidden {
  margin-top: -120px;
}
.landscape .st-name{
  height: 160px;
  width: 100vh;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-100%,0);
  padding-left: 80px;
  transition: margin-left .4s;
  font-size: 55px;
}
.landscape .st-name.hidden {
  margin-left: -232px;
}
.st-name h1 {
  margin: 0;
  font-weight: 600;
  padding-top: 8px;
}
.pr-cross {
  position: relative;
  width: 0.5em;
  height: 0.5em;
  margin-right: 2%;
  margin-left: -2%;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  transition: opacity .2s, margin-left.4s, border-left .4s;
}
.landscape .pr-cross {
  margin-left: 10px;
  margin-top: 3.5%;
  margin-left: -1em;
}
.portrait .pr-cross {
  margin-top: 3.5%;
}

/* .pr-cross:before, .pr-cross:after {
  content: '';
  height: 24px;
  border-left: 4px solid var(--globalLightVibrant);
  position: absolute;
  transform: rotate(-45deg);
  left: 8px;
  top: -2px;
  transition: border-color .4s;
} */
.pr-cross:before, .pr-cross:after {
  content: '';
    height: 119%;
    border-left: .1em solid var(--globalLightVibrant);
    position: absolute;
    transform: rotate(-45deg);
    left: 38%;
    top: -11%;
}
.st-name .pr-cross {
  width: 34px;
  opacity: 1;
  pointer-events: all;
  margin: 0;
    margin-top: 0.2em;
    margin-right: 15px;
}
.st-name .pr-cross:before, .st-name .pr-cross:after {
  border-left: 6px solid var(--darkVibrant);
}
.pr-cross:after {
  transform: rotate(45deg) !important;
}
.Home {
  user-select: none;
  --stripeHeight: 48px;
  --ty: 0px;
  --drag: 0;
}
.landscape .Home {
  height: 100vh;
  --stripeHeight: 80px;
}
.projectOpened-Navbar{
  position: absolute;
  bottom: 0;
  z-index: -1;
  line-height: 0;
}
</style>